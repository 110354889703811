(function($) {
$(document).ready(function () {

    let swiperInstances = [];

    $(".swiper-container.sliders").each(function(index, element){
        const $this = $(this);
        var $dataMode = (element.getAttribute('data-mode'));
        $this.addClass("instance-" + index);
        $this.parent().find(".swiper-pagination").addClass("pagination-" + index);
        $this.parent().find(".swiper-button-prev").addClass("prev-" + index); //prev must be unique (ex: some-slider-prev)
        $this.parent().find(".swiper-button-next").addClass("next-" + index); //next must be unique (ex: some-slider-next)

        if($dataMode === 'break-slide-mobile') {
            if($this.parents(".sidebar-menu").length){
                swiperInstances[index] = new Swiper(".instance-" + index, {

                    autoplay:{
                        delay: 5000,
                        disableOnInteraction: false
                    },

                    slidesPerView : 1.1,
                    slidesPerGroup: 1,
                    spaceBetween: 15,

                    loop: true,

                    pagination: {
                        el: '.pagination-' + index,
                        clickable: true,
                        type: 'fraction',

                        formatFractionCurrent: function(number){
                            return number.toString().padStart(2, '0');
                        },
                        formatFractionTotal: function(number){
                            return number.toString().padStart(2, '0');
                        }
                    },

                    breakpoints: {
                        576: {
                            slidesPerView: 1,
                        },

                    },

                    navigation: {
                        prevEl: ".prev-" + index,  //prev must be unique (ex: some-slider-prev)
                        nextEl: ".next-" + index, //next must be unique (ex: some-slider-next)
                    },
                });

            }
            else {
                swiperInstances[index] = new Swiper(".instance-" + index, {
                    autoplay: {
                        delay: 5000,
                    },
                    loop: true,
                    loopPreventsSlide: true,
                    loopedSlides: 2,

                    slidesPerView: 1.1,
                    slidesPerGroup: 1,
                    centeredSlides: true,
                    spaceBetween: 10,

                    pagination: {
                        el: '.pagination-' + index,
                        clickable: true,
                        type: 'fraction',

                        formatFractionCurrent: function(number){
                            return number.toString().padStart(2, '0');
                        },
                        formatFractionTotal: function(number){
                            return number.toString().padStart(2, '0');
                        }
                    },

                    breakpoints: {
                        576: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                            slidesOffsetBefore: 0,
                        },

                    },

                    navigation: {
                        prevEl: ".prev-" + index,  //prev must be unique (ex: some-slider-prev)
                        nextEl: ".next-" + index, //next must be unique (ex: some-slider-next)
                    },

                });
            }

        }

        else if ($dataMode === 'break-sortiment-slide-mobile'){
            swiperInstances[index] = new Swiper(".instance-" + index, {
                autoplay: {
                    delay: 5000,
                },

                slidesPerView: 1.1,
                spaceBetween: 10,

                pagination: {
                    el: '.pagination-' + index,
                    clickable: true,
                    type: 'fraction',

                    formatFractionCurrent: function(number){
                        return number.toString().padStart(2, '0');
                    },
                    formatFractionTotal: function(number){
                        return number.toString().padStart(2, '0');
                    }

                },

                breakpoints: {
                    576: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        slidesOffsetBefore: 0,
                    }
                },

                navigation: {
                    prevEl: ".prev-" + index,  //prev must be unique (ex: some-slider-prev)
                    nextEl: ".next-" + index, //next must be unique (ex: some-slider-next)
                },

            });
        }

        else {
            swiperInstances[index] = new Swiper(".instance-" + index, {
                autoplay: {
                    delay: 5000,
                },
                    pagination: {
                    el: '.pagination-' + index,
                    clickable: true,
                    type: 'fraction',

                    formatFractionCurrent: function(number){
                        return number.toString().padStart(2, '0');
                    },
                    formatFractionTotal: function(number){
                        return number.toString().padStart(2, '0');
                    }
                },

                navigation: {
                    prevEl: ".prev-" + index,  //prev must be unique (ex: some-slider-prev)
                    nextEl: ".next-" + index, //next must be unique (ex: some-slider-next)
                },

            });
        }


        if($this.parent().find(".swiper-slide").length === 1){
            $this.parent().find('.swiper-wrapper').addClass( "disabled" );
            $this.parent().find(".slider-nav").addClass( "disabled" );
            $this.parent().find('.slide-image').addClass('before-progress-bar-show');

        }

        if($this.parents(".sidebar-menu").length){
                swiperInstances[index].autoplay.stop();
        }

        $(".menu-toggle .icon-menu").click(function(){
            swiperInstances[index].autoplay.start();
            var activeEl = element.querySelector('.swiper-slide-active');
            $(activeEl).siblings().find('.slide-image').removeClass('before-progress-bar');
            $(activeEl).find('.slide-image').addClass('before-progress-bar');
        });

        $(".sortiment-left, .sortiment-right").click(function(){
            swiperInstances[index].autoplay.start();
            if($(swiperInstances[index]).parents().find('.system-extra .product-extra')) {
                var activeEl = element.querySelector('.swiper-slide-active');
                $(activeEl).siblings().find('.slide-image').removeClass('before-progress-bar');
                $(activeEl).find('.slide-image').addClass('before-progress-bar');
            }
        });

        swiperInstances[index].on('slideChangeTransitionEnd', function () {
            if($(swiperInstances[index]).parents().find('.system-extra .product-extra')) {
                var activeEl = element.querySelector('.swiper-slide-active');
                $(activeEl).siblings().find('.slide-image').removeClass('before-progress-bar');
                $(activeEl).find('.slide-image').addClass('before-progress-bar');
            }
        })

        swiperInstances[index].on('slideChange', function () {
            $('.swiper-container-horizontal').each(function () {
                $(this).addClass('slider-indexing');



            });
        });
        swiperInstances[index].on('transitionEnd', function () {
            $('.swiper-container-horizontal').each(function () {
                $(this).removeClass('slider-indexing');
                var content = $( ".container .contenance-gutex-content-menuslider .swiper-slide-active .gutex-slider-wrapper .slide-content" );

                var height = content.innerHeight();
                var heightAdd = 345;

                $(".slider-button").css({ top: height + heightAdd});
            });
        });

        swiperInstances[index].on('sliderMove', function () {
            $('.swiper-container-horizontal').each(function () {
                $(this).addClass('slider-indexing');
            });
        });
    });


    var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });
    var galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        thumbs: {
            swiper: galleryThumbs
        }
    });

    //Close Sortiment Slider on Tab Switch
    var tabs = $(".tab-horizontal").find(".tab-pane");
    tabs.each(function () {
        var sortiment = $(this).find(".sortiment-element");
        if(sortiment.length > 0) {
            $(this).css('transition', 'none');
        }
    });

    $(".nav-item").click(function(){
        $(".larger-circle").removeClass("make-smaller");
        $(".smaller-circle").removeClass("move-left move-right");
        $(".sortiment-left").removeClass("clicked");
        $(".sortiment-right").removeClass("clicked");
        $(".system-info").removeClass("system-down");
        $(".product-extra").removeClass("product-extra-up");
        $(".system-extra").removeClass("system-extra-up");
        $(".product-info").removeClass("product-down");
    });

});

})(jQuery); // End of use strict
