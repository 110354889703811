(function($) {
    "use strict"; // Start of use strict

    $(document).ready(function() {
        $('.support-inner-content ').find('form:not(.salesforce)').submit(function(e) {
            var form = $(this);

            $('div.errorInput').removeClass('errorInput');
            var errorLabel = $('div .input label', form[0]);
            $.each(errorLabel, function(index, value) {
                var input = errorLabel[index].nextElementSibling;
                if( input != null){
                    if(input.value == ''){
                        var labelId = errorLabel[index].htmlFor;
                        $('#'+labelId).parent().addClass('errorInput');
                    }
                }else{
                    if(($(errorLabel[index]).find('span').hasClass('required')) && (!$(errorLabel[index].previousElementSibling).hasClass('checked'))){
                        var labelId = errorLabel[index].htmlFor;
                        $('#'+labelId).parent().addClass('errorInput');
                    }
                }
            });
            var select = $('.selectpicker', form[0]);
            if( select.length != 0){
                $.each(select, function(index, value) {
                    var selectId = select[index].id;
                    if( select[index].selectedIndex == 0){
                        $('#'+selectId).parent().parent().addClass('errorInput');
                    }
                });
            }
            var recaptchaEl = $('.g-recaptcha', form[0]);
            if(recaptchaEl.length != 0){
                var recaptcha = recaptchaEl[0].children[0].childNodes[1].value;
                recaptchaEl.children()[0].style.border = '1px solid #d3d3d3'
                if (recaptcha === "") {
                    e.preventDefault();
                    recaptchaEl.children()[0].style.border = '1px solid #E94739'
                }
            }
            var emaillabel = $('div .input div label', form[0]);
            if (emaillabel.length) {
            $.each(emaillabel, function(index, value) {
                if( emaillabel[index].innerText == 'E-Mail-Adresse*'){
                    var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                    if(emaillabel[index].nextElementSibling.value.match(mailformat)){


                        var button = form.find('button[type="submit"]');
                        var url = form.attr('action');
                        var data = form.serialize()
                            + '&'
                            + encodeURI(button.attr('name'))
                            + '='
                            + encodeURI(button.attr('value'));

                        $.ajax({
                            type: "POST",
                            url: url,
                            data: data,
                            mimeType: 'multipart/form-data',
                            contentType: false,
                            cache: false,
                            processData: false,
                            beforeSend: function () {
                                button.replaceWith('<div class="loading-wrap">\n' +
                                    '<div class="loading-hole"></div>\n' +
                                    '</div>');
                            },
                            success: function(response) {
                                var elements = $(response);
                                var found = $('.formResult', elements);
                                if(found.length != 0){
                                    $('.support-section').replaceWith(found.html());
                                }
                                else{
                                    $('.support-inner-content ').find('div.loading-wrap').replaceWith(button);
                                }
                            },
                            error: function (e) {
                            }
                        });

                    }else{
                        var labelid = emaillabel[index].htmlFor;
                        $('#'+labelid).parent().addClass('errorInput');
                    }
                }
            });
            }
            e.preventDefault();
        });

        $('.accordion-inner ').find('form:not(.salesforce)').submit(function(e) {
            var form = $(this);

            $('div.errorInput').removeClass('errorInput');
            var errorLabel = $('div .input label', form[0]);
            $.each(errorLabel, function(index, value) {
                var input = errorLabel[index].nextElementSibling;
                if( input != null){
                    if(input.value == ''){
                        var labelId = errorLabel[index].htmlFor;
                        $('#'+labelId).parent().addClass('errorInput');
                    }
                }else{
                    if(($(errorLabel[index]).find('span').hasClass('required')) && (!$(errorLabel[index].previousElementSibling).hasClass('checked'))){
                        var labelId = errorLabel[index].htmlFor;
                        $('#'+labelId).parent().addClass('errorInput');
                    }
                }
            });

            var select = $('.selectpicker', form[0]);
            if(select.length != 0){
                $.each(select, function(index, value) {
                    var selectId = select[index].id;
                    if( select[index].selectedIndex == 0){
                        $('#'+selectId).parent().parent().addClass('errorInput');
                    }
                });
            }
            var recaptchaEl = $('.g-recaptcha', form[0]);
            if(recaptchaEl.length != 0){
                var recaptcha = recaptchaEl[0].children[0].childNodes[1].value;
                recaptchaEl.children()[0].style.border = '1px solid #d3d3d3'
                if (recaptcha === "") {
                    e.preventDefault();
                    recaptchaEl.children()[0].style.border = '1px solid #E94739'
                }
            }
            var emailLabel = $('div .input div label', form[0]);
            if (emaillabel.length) {
                $.each(emailLabel, function (index, value) {
                    if (emailLabel[index].innerText == 'E-Mail*') {
                        var mailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                        if (emailLabel[index].nextElementSibling.value.match(mailFormat)) {
                            var button = form.find('button[type="submit"]');
                            var url = form.attr('action');
                            var data = form.serialize()
                                + '&'
                                + encodeURI(button.attr('name'))
                                + '='
                                + encodeURI(button.attr('value'));
                            $.ajax({
                                type: "POST",
                                url: url,
                                data: data,
                                mimeType: 'multipart/form-data',
                                contentType: false,
                                cache: false,
                                processData: false,
                                beforeSend: function () {
                                    button.replaceWith('<div class="loading-wrap">\n' +
                                        '<div class="loading-hole"></div>\n' +
                                        '</div>');
                                },
                                success: function (response) {
                                    var elements = $(response);
                                    var found = $('.formResult', elements);
                                    if (found.length != 0) {
                                        $('.accordion-inner').find('form').replaceWith(found.html());
                                    } else {
                                        $('.accordion-inner').find('div.loading-wrap').replaceWith(button);
                                    }
                                },
                                error: function (e) {
                                }
                            });
                        } else {
                            var labelId = emailLabel[index].htmlFor;
                            $('#' + labelId).parent().addClass('errorInput');
                        }
                    }
                });
            }
            e.preventDefault();

        });

        $('form:not(.salesforce)').submit(function(e) {
            var form = $(this);

            $('div.errorInput').removeClass('errorInput');
            var errorLabel = $(this).find('div .input label').has('span');
            $.each(errorLabel, function(index, value) {
                var input = errorLabel[index].nextElementSibling;
                if( input != null){
                    if(input.value == ''){
                        var labelId = errorLabel[index].htmlFor;
                        $('#'+labelId).parent().addClass('errorInput');
                    }
                }else{
                    if(($(errorLabel[index]).find('span').hasClass('required')) && (!$(errorLabel[index].previousElementSibling).hasClass('checked'))){
                        var labelId = errorLabel[index].htmlFor;
                        $('#'+labelId).parent().addClass('errorInput');
                    }
                }
            });
            var select = $('.selectpicker', form[0]);
            if(select.length != 0){
                $.each(select, function(index, value) {
                    var selectId = select[index].id;
                    if( select[index].selectedIndex == 0){
                        $('#'+selectId).parent().parent().addClass('errorInput');
                    }
                });
            }
            var recaptchaEl = $('.g-recaptcha', form[0]);
            if(recaptchaEl.length != 0){
                var recaptcha = recaptchaEl[0].children[0].childNodes[1].value;
                recaptchaEl.children()[0].style.border = '1px solid #d3d3d3'
                if (recaptcha === "") {
                    e.preventDefault();
                    recaptchaEl.children()[0].style.border = '1px solid #E94739'
                }
            }
            var emailLabel = $('div .input div label', form[0]);
            if (emaillabel.length) {
                $.each(emailLabel, function (index, value) {
                    if (emailLabel[index].innerText == 'E-Mail*' || emailLabel[index].innerText == 'E-Mail-Adresse*') {
                        var mailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                        if (emailLabel[index].nextElementSibling.value.match(mailFormat)) {
                            var button = form.find('button[type="submit"]');
                            var url = form.attr('action');
                            var data = form.serialize()
                                + '&'
                                + encodeURI(button.attr('name'))
                                + '='
                                + encodeURI(button.attr('value'));
                            $.ajax({
                                type: "POST",
                                url: url,
                                data: data,
                                mimeType: 'multipart/form-data',
                                contentType: false,
                                cache: false,
                                processData: false,
                                beforeSend: function () {
                                    button.replaceWith('<div class="loading-wrap">\n' +
                                        '<div class="loading-hole"></div>\n' +
                                        '</div>');
                                },
                                success: function (response) {
                                    var elements = $(response);
                                    var found = $('.formResult', elements);
                                    if (found.length != 0) {
                                        $('form').replaceWith(found.html());
                                        const queryString = window.location.search;
                                        const urlParams = new URLSearchParams(queryString);
                                        if (urlParams.has('link')) {
                                            const productUrl = urlParams.get('link');
                                            setTimeout(function () {
                                                window.location.href = productUrl;
                                            }, 5000);
                                        }

                                    } else {
                                        var form = $(this.url.substring(this.url.indexOf('#')), elements);
                                        var formId = form[0].id;
                                        $('#' + formId).find('div.loading-wrap').replaceWith(button);
                                    }
                                },
                                error: function (e) {
                                }
                            });
                        } else {
                            var labelId = emailLabel[index].htmlFor;
                            $('#' + labelId).parent().addClass('errorInput');
                        }
                    }
                });
            }
            e.preventDefault();
            if ($(".errorInput:visible").length > 0) {
                $('html, body').scrollTop( $(".errorInput:visible:first").offset().top - 70);
            }
        });
        var x=1;
        $('.addMore').click(function(e){

            e.preventDefault();
            var label = $(this).parent().find('label')[0].innerText.replace(/\*$/, '');
            var id = $(this).parent().find('input')[0].id;
            var name = $(this).parent().find('input')[0].name;
            $('<div class="addedInput"><label for="'+id+'[' + x + ']">'+ label +'</label><span class="remove-button">(<button type="button" class="removeInput">entfernen</button>)</span><input type="text" class="form__text-input form-control" id="'+id+'[' + x + ']" name="'+name+'[' + x + ']"/><br/></div>').insertBefore($(this));
            x++;
        });

        $(document).on('click', '.removeInput', function () {
            $(this).closest('.addedInput').remove();
        });


        //custom radio button uncheck function

        $('input[type="radio"]:not(.option-set)').click(function () {
            if (this.previous) {
                this.checked = false;
                if($(this).siblings('.sub-group')){
                    $(this).prop('checked', status).change();
                    $(this).siblings('.sub-group').children('.checkbox_container').children('span').children('input').prop("checked", false)
                    $(this).siblings('.sub-group').children('.checkbox_container').children('span').children('input').prop("checked", status).change();
                }
            }

            this.previous = this.checked;
            if($(this).siblings('.sub-group')) {
                $(this).parent().siblings('.toggle-group').children('input').prop('checked', false);
                $(this).parent().siblings('.toggle-group').children('input').prop('checked', status).change();
                $(this).parent().siblings('.toggle-group').children('.sub-group').children('.checkbox_container').children().children('input').prop('checked', false);
                $(this).parent().siblings('.toggle-group').children('.sub-group').children('.checkbox_container').children().children('input').prop('checked', status).change()
            }
        });

        // Closes the sidebar menu

        $(".icon-menu").on("click", function () {
            $("#sidebar-wrapper").toggleClass("active");
            $(this).parent().toggleClass("active");
            $('body').toggleClass("menu-opened");
            $(".sidebar-nav-item, .current, .active").removeClass("hover");
        });
        $(document).ready(function () {

            $(".gutex-sticky-button").click(function (event) {
                event.preventDefault();
                $('body').toggleClass('overflow');
            });

            $('.form-field-sm').parents('.clearfix').addClass('field-sm');
            $('.single-line-dropdown').parents('.clearfix').addClass('single-line');


        });
        $(document).ready(function () {
            let title = "";
            $(".faq-sidebar-link").on("click", function () {
                title = $(this).parent().find(".faq-sidebar-title")[0].innerHTML;

            });


        });

        $(document).on("click", function (event) {
            if (!$(event.target).closest("#sidebar-wrapper, .icon-menu").length) {
                $("#sidebar-wrapper").removeClass("active");
                $(".menu-toggle").removeClass("active");
                $("body").removeClass("menu-opened");
                $(".sidebar-nav-item").removeClass("submenues-openend");
                $(".current").removeClass("submenues-openend");
                $(".appended.sidebar-nav-item").remove();
            }
        });


        $("#menu-main .sidebar-nav-item ul, #menu-main .current ul, #menu-main .active ul").append("<span class='back-button'><p>Zurück</p></span>");
        $('li.sidebar-nav-item').find('ul').parent().addClass('has-submenu');

        $('li.current').find('ul').parent().addClass('has-submenu');
        $('li.active').find('ul').parent().addClass('has-submenu');
        $('li.sidebar-nav-item').find('ul').siblings().addClass('active-link');
        $('li.current').find('ul').siblings().addClass('active-link');
        $('li.active').find('ul').siblings().addClass('active-link');


        $(".active-link").on('click', function (ev) {
            ev.preventDefault();
            $(this).parent().addClass("hover");
            $(this).parent().siblings('li').addClass('submenues-openend');
            var linkText = $(this).html();
            var link = $(this).attr('href');
            $(this).siblings('ul').append("<li class='appended sidebar-nav-item'><a href=" + link + ">" + linkText + "</a></li>");
        });

        $(".back-button").click(
            function () {
                setTimeout(
                    function () {
                        $(".sidebar-nav-item, .current, .active").removeClass("hover");
                        $(".sidebar-nav-item, .current, .active").siblings('li').removeClass('submenues-openend');
                        $(".appended").remove();
                    },
                    300
                );
            });

        //Search button click
        $(".search .icon-search").on('click', function (e) {
            e.preventDefault();
            $(".search-block").toggleClass("open");
            $(this).parent().toggleClass("active");
            $(".language-switcher").removeClass('active');
            $(".contenance-gutex-language").removeClass("open");
            $('#search-page-suggestions').parent().removeClass('active');
            $('.gutex-support-sidebar').removeClass('opened');
            $('.gutex-sticky-button').removeClass('active');
        });

        //Language button click
        $(".language-switcher").on('click', function (e) {
            e.preventDefault();
            $(".contenance-gutex-language").toggleClass("open");
            $(this).toggleClass("active");
            $(".search").removeClass('active');
            $(".search-block").removeClass("open");
            $('.gutex-support-sidebar').removeClass('opened');
            $('.gutex-sticky-button').removeClass('active');

        });


        // Smooth scrolling using jQuery easing
        $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000, "easeInOutExpo");
                    return false;
                }
            }
        });
        // Smooth scrolling using jQuery easing
        $('.faq-sidebar-link[href*="#"]:not([href="#"])').click(function () {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            $('.card').removeClass('active-acc');
            $('.accordion-body').removeClass('show');
            if (target.length) {
                target.parent().parent().parent().parent().parent().addClass("active-acc");
                target.parent().parent().parent().parent().addClass("show");

                $('html, body').animate({
                    scrollTop: target.parent().parent().parent().parent().parent().offset().top
                }, 1000, "easeInOutExpo");
                return false;
            }

        });


        // Scroll on load if #hashtag set in URL
        if( (window.location.href.indexOf("faq") != -1) || (window.location.href.indexOf("ueber-uns") != -1)){
            var target = $(window.location.hash);

            window.location.hash = "";

            if (target.length) {
                target.parent().parent().parent().parent().parent().addClass("active-acc");
                target.parent().parent().parent().parent().addClass("show");
                $('html, body').animate({
                    scrollTop: $(target).parent().parent().parent().parent().parent().offset().top
                }, 700, 'swing', function () {
                });
            }

            $(".card").click(function() {
                $(".card").not($(this)).removeClass('active-acc');
                $(".card").not($(this)).children().removeClass('show');
                $(".card").not($(this)).find(".ficon").removeClass("icon-minus").addClass("icon-plus");
            });
        }
        var target = $(window.location.hash);
        if (target.length) {
            var id = window.location.hash.split('#')[1];
            target.parent().siblings().removeClass('active');
            target.parent().siblings().children().removeClass('active');
            target.addClass('active');
            target.parent().addClass('active');
            target.parent().parent().parent().parent().children().find('#tab-'+ id).addClass('active show');
            target.parent().parent().parent().parent().children().not('#tab-'+ id).removeClass('active show');
        }else {
            var tabContent = $('.tab-content');
            for(i=0; i<tabContent.length; i++){
                $('.tab-pane:nth-child(1)').addClass('active show');
            }
        }

        $('.menu-toggle').click(function() {
            $(".search-block").removeClass("open");
            $(".search").removeClass("active");
            $(".contenance-gutex-language").removeClass("open");
            $(".language-switcher").removeClass("active");
            $('.gutex-support-sidebar').removeClass('opened');
            $('.gutex-sticky-button').removeClass('active');
            $('body').removeClass('overflow');
        });

        // Closes responsive menu when a scroll trigger link is clicked
        $('#sidebar-wrapper .js-scroll-trigger').click(function() {
            $("#sidebar-wrapper").removeClass("active");
            $(".menu-toggle").removeClass("active");
            $(".menu-toggle > .fa-bars, .menu-toggle > .fa-times").toggleClass("fa-bars fa-times");
        });

        //Country switch button
        $('#menu-country li').on('click', function(){
            $('#show-country').text($(this).text());
        });

        $(document).ready(function() {
            $('#show-country').text($("#menu-country [class='current']").text());
            var countryName = $('#show-country').text();
            $(".language-switcher-name").text(countryName);
        });

        //Language switch button
        $('#menu-language li').on('click', function(){
            $('#show-language').text($(this).text());
        });

        $(document).ready(function() {
            $('#show-language').text($("#menu-language [class='current']").text());
        });



        //Logo wall

        $('.owl-carousel').owlCarousel({
            loop:true,
            margin:30,
            items:4,
            navigation:true,
            navigationText: [
                "<i class='icon-arrow-left'></i>",
                "<i class='icon-arrow-right'></i>"
            ]
        })

        if ($(window).width() > 480) {

            var sliderDiv = $(".owl-item .owl-img");
            for(var i = 0; i < sliderDiv.length; i+=2) {
                sliderDiv.slice(i, i+2).wrapAll('<div class="item" />');
            }
        }

        //Person search by zip code
        var allPersons = [];
        $("#search-person-form button").on('click',function (e) {
            e.preventDefault();

            var personData = $(".search-container").parent().find('.person-container').data('person');
            allPersons = $(personData).find('.contenance-gutex-content-person');
            var filter = $('#search-person-form input').val().toLowerCase();
            $('.no-result-person').css('display', 'none');
            $('.personData ')[0].innerHTML = '';
            $(allPersons).each(function () {
                const num = $(this).find("#zipcodeId")[0].innerHTML;
                const numArr = num.split(',');
                var result = numArr.reduce((a, str) => {
                    if (!str.includes('-')) {
                        a.push((str));
                        return a;
                    }
                    var [low, high] = str.split('-');
                    for(let i = low; i<= high; i++){
                        a.push(i);
                        if(low.startsWith('0')){
                            a[i] = '0' + a[i];
                        }
                    }
                    return a;
                }, []);

                var strResult = result.map(function(e){return e.toString()});

                var startsWithN = strResult.filter((el) => el.startsWith(filter));

                if ( (startsWithN) != 0 && (startsWithN.length > 0) && (filter.length > 0) ) {
                    $('.no-result-person').css('display', 'none');
                    $('.personData ').append(this);
                    $(this).find('.gutex-person').css('display', 'block');
                }
                var totalResult = $('.personData ').find('.gutex-person').length;
                if( (totalResult == 0 )&& (filter.length > 0)){
                    $('.no-result-person').css('display', 'block');
                }
            });
            sameHeight();
        });

        //sticky support button
        $(".gutex-sticky-button").on('click',function (e) {
            e.preventDefault();
            $(".gutex-support-sidebar").toggleClass("opened");
            $(this).toggleClass("active");
            $(".search-block").removeClass("open");
            $(".search").removeClass("active");
            $(".contenance-gutex-language").removeClass("open");
            $(".language-switcher").removeClass("active");
        });

        $(".faq-container .card").click(function() {
            $(".faq-container .card").not($(this)).removeClass('active-acc');
            $(".faq-container .card").not($(this)).children().removeClass('show');
            $(".faq-container .card").not($(this)).find(".ficon").removeClass("icon-minus").addClass("icon-plus");
        });

        $(".nav-item").on('click', function() {

            $(this).addClass('active');
            $(this).siblings().removeClass('active');
        });


        //Support faq

        $(".faq-title").on('click',function (e) {
            e.preventDefault();
            $(this).parent().addClass("active");
            $(".support-search").addClass("show");
        });

        $(".support-title").on('click',function (e) {
            e.preventDefault();
            $(this).parent().addClass("active");

        });

        $(".support-level").on('click',function () {
            $(".faq-container").removeClass("active");
            $(".support-search").removeClass("show");
        });

        $(".support-sticky-button-title").on('click',function () {
            $(".support-sticky-button-content").toggleClass("show");
        });

        var supportContainer = $('.support-container');
        for(var i = 0; i < supportContainer.length; i++){
            if($(supportContainer).find('form').length !== 0){
            }else{
                $( "button.nxt-level" ).hide();
            }
        }

        $(".nxt-level").click(function () {

            $('fieldset:first-child input, fieldset:first-child textarea').each(function(){
                var val = $(this).val();
                $(this).toggleClass("errorInput",val.length==0);
                $(this).parent().toggleClass("errorInput",val.length==0);
            })
        });


        $(".custom-checkbox input").on('change', function() {

            if ($(this).hasClass('checked')) {
                $(this).removeClass('checked');
            } else {
                $(this).addClass('checked');
            }
        });

        $("form.salesforce").each(function () {
            $(this).on('submit', function (e) {
                var form = $(this);
                $(form).find('input, textarea').each(function () {
                    if (!$(this).prop('required')) {

                    } else {
                        $(this).parent().removeClass('errorInput');
                        if((($(this).val() == '') || ($(this).attr('type') == 'checkbox' && !$(this).hasClass('checked') ))){
                            $(this).parent().addClass('errorInput');
                        }
                        if($(this).attr('type') == 'email'){
                            var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                            if(!$(this).val().match(mailformat)){
                                $(this).parent().addClass('errorInput');
                            }
                        }
                    }
                });
                $(form).find('select').each(function () {
                    if (!$(this).prop('required')) {

                    } else {
                        $(this).parent().parent().parent().removeClass('errorInput');
                        if($(this).val() == ''){
                            $(this).parent().parent().parent().addClass('errorInput');
                        }

                    }
                });
                var recaptcha =  $(form).find('#g-recaptcha-response').val();
                if (recaptcha === "") {
                    e.preventDefault();
                    $(form).find('.g-recaptcha').children()[0].style.border = '1px solid #E94739'
                }

                if ($(".errorInput:visible").length > 0) {
                    $('html, body').scrollTop( $(".errorInput:visible:first").offset().top - 70);
                }
            });
        });

        $('input[type="text"], textarea').blur(function(){
            if($(this).val()){
                $(".nxt-level").click(function () {
                    $(".support-inner-content form").addClass("form-steps");
                    $(".current-level").text('02');
                    $(this).hide();
                });
            }
        });
        $(".previous-level").on('click',function (e) {
            e.preventDefault();

            if ($("form").hasClass("form-steps")) {
                $(".support-inner-content form").removeClass("form-steps");
                $(".current-level").text('02');
                $(".nxt-level").show();
            }else{
                $(".support-container").removeClass("active");
            }
        });

        //Search on support section
        var searchSupport = $(".support-search");
        searchSupport.each(function (){
            var searchSuportInput = $(this).find("input");
            searchSuportInput.on("keyup", function () {
                var val = $(this).val().toLowerCase();
                $(".faq-sidebar-element").each(function () {
                    $(this).toggle($(this).text().toLowerCase().includes(val));
                });
            });
        })

        $(".nav-item").click(function(){
            $(".rounded-circle").removeClass("make-smaller");
        });


        //image light box

        $(".open-image").on("click", function() {
            var $this = $(this);
            $this.closest('.contenace-gutex-image').find('.modal.fade.image').addClass('show');

        });
        $(".close").on("click", function() {

            $('.modal.fade.image').removeClass('show');
            $( ".modal-backdrop.fade.show" ).remove();
        });

        // Scroll to top button appears
        $(document).scroll(function() {
            var scrollDistance = $(this).scrollTop();
            if (scrollDistance > 300) {
                $('.scroll-to-top').fadeIn();
            } else {
                $('.scroll-to-top').fadeOut();
            }
        });

        // scrolls back to 0px on click
        $('.scroll-to-top').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 400);
            return false;
        });

        // Share Button

        $( "#share-button" ).click(function() {
            $( "#share-social" ).slideToggle( "slow", function() {
                $('.shareBtn').toggleClass("opened");
            });
        });
        //Event info pox

        $(".event-info p").on("click", function() {

            $('.event-sticky-container').toggleClass('show');
        });
        //Result page

        if ($("#main").find(".flowpack-searchplugin-search").length > 0){
            $("#teaser").hide();
        }

        $('.headline-basic h1, .headline-basic h2, .headline-basic h3, .headline-basic h4, .headline-basic h5, .headline-basic h6').wrapInner('<span />');


        //Hide header on scroll

        $(function(){
            var lastScrollTop = 0, delta = 5;
            var x = window.matchMedia("(max-width: 768px)")

            $(window).scroll(function(event){
                var st = $(this).scrollTop();

                if(Math.abs(lastScrollTop - st) <= delta)
                    return;

                if (st > lastScrollTop && st > 120){
                    //scroll down
                    $("#header").addClass('scroll-down');
                    $(".search-page-form").addClass('scroll-down');
                    $(".event-sticky-container").addClass('scroll-down');

                } else {
                    //scroll up
                    $("#header").removeClass('scroll-down');
                    $(".search-page-form").removeClass('scroll-down');
                    $(".event-sticky-container").removeClass('scroll-down');

                    //small devices
                    if (x.matches) {

                    } else {

                    }
                }

                lastScrollTop = st;
            });
        });

        (function() {
            var elements;
            var windowHeight;

            function init() {
                elements = document.querySelectorAll('.hide-el');
                windowHeight = window.innerHeight;
            }

            function checkPosition() {
                for (var i = 0; i < elements.length; i++) {
                    var element = elements[i];
                    var positionFromTop = elements[i].getBoundingClientRect().top;
                    if (positionFromTop - windowHeight <= 0) {
                        element.classList.add('animate__fadeIn');
                        element.classList.remove('hide-el');
                    }
                }

            }

            window.addEventListener('scroll', checkPosition);
            window.addEventListener('resize', init);

            init();
            checkPosition();
        })();

        (function() {
            var navElements;
            var windowHeight;
            function init() {
                navElements = document.querySelectorAll('.hide-nav');
                windowHeight = window.innerHeight;
            }

            function checkPositionnav() {
                for (var i = 0; i < navElements.length; i++) {
                    var element = navElements[i];
                    var positionFromTop = navElements[i].getBoundingClientRect().top;
                    if (positionFromTop - windowHeight <= 0) {
                        element.classList.add('animate__fadeInLeft');
                        element.classList.remove('hide-nav');
                    }
                }
            }

            window.addEventListener('scroll', checkPositionnav);
            window.addEventListener('resize', init);

            init();
            checkPositionnav();
        })();
    });
    $(document).ready(function(){
        if($('.mod_jumptonav').length < 1){

        } else {
            $('.mod_jumptonav').each(function (i,e) {
                var $objParent = $(e).closest('.container');
                var navElement = $(e).find('nav');
                if(navElement.children().length === 0) {
                    navElement.append('<ul></ul>');
                    // COLLLECT ITEMS AND APPEND TO NAV LIST
                    $('.jumptonav__item').each(function(index,element){
                        var strCurrent = '';
                        if($(window).scrollTop() === 0) {
                            strCurrent = (index === 0) ? ' class="current"' : '';
                        }
                        $(e).find('ul').append('<li' + strCurrent + '><a href="#' + $(element).attr('id') + '"><span>' + $(element).attr('data-name') + '</span></a></li>');
                    });
                    if($(window).width() > 414 ) {
                        $(e).portamento({wrapper: $objParent, gap: 120});
                    }
                    var scrollnav = $(e).onePageNav({
                        begin: function(){
                            if(window.matchMedia("(max-width: 576px)").matches) {
                                $(".btn-toggle-nav").trigger('click');
                            }
                        }
                    });

                    if($(window).scrollTop() > 1) {
                        $(window).scrollTop($(window).scrollTop()+1);
                        $(window).scrollTop($(window).scrollTop()-1);
                    }

                }
            });
        }
        $(".btn-toggle-nav").on('click', function(event){
            if($('.mod_jumptonav').is(':visible'))   {
                $('.mod_jumptonav').hide();
                $(this).toggleClass('icon-open-x icon-close-x');
            }
            else {
                $('.mod_jumptonav').show();
                $(this).toggleClass('icon-close-x icon-open-x');
            }
        });
        if(window.matchMedia("(max-width: 576px)").matches) {
            $(window).scroll(function () {
                var scroll = $(window).scrollTop();

                if ($(target).length) {
                    if ((scroll > target.offsetTop - (window.outerHeight * 0.9)) && (target.getBoundingClientRect().bottom > (window.innerHeight - 50))) {
                        target.querySelector('.btn-toggle-nav').parentElement.classList.add('fixed-nav');
                        target.querySelector('.mod_jumptonav').classList.add('fixed-list');
                    } else {
                        target.querySelector('.btn-toggle-nav').parentElement.classList.remove('fixed-nav');
                        target.querySelector('.mod_jumptonav').classList.remove('fixed-list');
                    }
                }
            })
        }
        let target = document.querySelector('.observed-nav-container');
    });

    /* Scroll Down Button */
    function scrollDown(){
        const link = document.getElementById("scroll_down");
        link.addEventListener("click", clickHandler);

        function clickHandler(e) {
            e.preventDefault();
            const offset = document.getElementById("main").offsetTop;

            scroll({
                top: offset,
                behavior: "smooth"
            });
        }
    }

    function privacyLink() {
        $(".custom-control-label:contains('Datenschutzbestimmung')").html(function(_, html) {
            var domain = window.location.href;
            var a = document.createElement('a');
            a.href =  'https://'+ domain.split(/[/?#]/)[2] + '/datenschutz';
            a.classList.add("privacy-link");
            a.innerHTML = "Datenschutzbestimmung";
            return html.replace(/(Datenschutzbestimmung)/g, $(a)[0].outerHTML);
        });
    }


    function leftPosition() {

        $(window).on("resize", function () {

            $('.navigation_jumpto').each(function () {
                var offset = $(this).offset();
                var offsetLeft = offset.left - 30;
                $(this).css('margin-left', '-=' + offsetLeft + 'px');
            });

        }).resize();
    }
    function autoFocus() {
        $(".search").click(function() {
            var e = $(".search-content");
            setTimeout((function () {
                    e.find("input").focus();
                }
            ), 500)

        });
    }
    function teaserLevel() {
        if($("body").hasClass("header-custom-3")){
            $("body").removeClass("page-level-4");

        }
    }
    function external() {
        $('.neos-nodetypes-text a').each(function() {
            if ($(this).attr('target') == '_blank' && !$('this').not(':has(i)') == false) {
                $(this).addClass('external-link');
            }
        //TODO: Replace shop domain with dynamic url (Ticket: GTX-1264)
            var hrefShop = $('[href^="https://staging.shop.gutex.de/"]');
            var hrefDev = $('[href^="http://dev.shop.gutex.contenance.de/"]');
            var hrefSecureDev = $('[href^="https://dev.shop.gutex.contenance.de/"]');
            var hrefPrelive = $('[href^="https://prelive.shop.gutex.de/"]');
            var hrefLive = $('[href^="https://shop.gutex.de/"]');
            if ((hrefShop.length > 0) || (hrefDev.length > 0) || (hrefSecureDev.length > 0) || (hrefPrelive.length > 0) || (hrefLive.length > 0)) {
                hrefShop.each(function() {
                    $(this).removeClass('external-link');
                    $(this).removeAttr('target');
                });

                hrefDev.each(function() {
                    $(this).removeClass('external-link');
                    $(this).removeAttr('target');
                });

                hrefSecureDev.each(function() {
                    $(this).removeClass('external-link');
                    $(this).removeAttr('target');
                });

                hrefPrelive.each(function() {
                    $(this).removeClass('external-link');
                    $(this).removeAttr('target');
                });

                hrefLive.each(function() {
                    $(this).removeClass('external-link');
                    $(this).removeAttr('target');
                });
            }

        });
    }
    function wrapLink() {
        $('.contenance-gutex-content-imagetextbutton').each(function() {
            if($(this).children().first().is('.style-3, .style-5, .style-11')) {
                $(this).find('a').wrapInner( "<span></span>");
            }
        });
    }
    function replace() {
        $('.faq-section ').each(function () {

            $('.title-sub h3', this).each(function () {
                var title = this.innerText;
                var newtitle = title.replace("&amp;","&");
                this.innerText = newtitle;
            })
        });

    }

    $(document).ready(function () {
        //boxLink();
        leftPosition();
        autoFocus();
        scrollDown();
        privacyLink();
        teaserLevel();
        external();
        wrapLink();
    });

})(jQuery); // End of use strict

function copyLink(id) {
    //alert('Hello');
    var parent = $("#" + id).parentsUntil("div.ce_accordion");
    var card = parent[3];

    var divContent = $(card).children();

    var idToLink = divContent[1].id;

    var link = document.createElement('a');
    var pageLink = window.location.href;
    if(pageLink.includes("#")) {
        pageLink = pageLink.substring(0, pageLink.indexOf('#'));
    }
    link.href = pageLink + "#" + idToLink;
    var copyText = document.createElement('input');
    copyText.id = "hrefInput";
    document.body.appendChild(link);
    copyText.value = link.href;
    document.body.appendChild(copyText);
    var copyTextarea = document.getElementById('hrefInput');
    // copyTextarea.focus({preventScroll: true});
    copyTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(link);
    document.body.removeChild(copyText);
}
function copyLinkSmall(id) {
    var link  = document.createElement('link');
    var parent = $("#" + id).parentsUntil("div.video-el");

    var dloadedDiv = $(parent[2]);

    var dloaded = $(dloadedDiv).children();

    var dDiv = $(dloaded[1])[0];

    $(dDiv).show();
    $(dDiv).fadeOut(2000).hide(0);
    var pageLink = window.location.href;
    if(pageLink.includes("#")) {
        pageLink = pageLink.substring(0, pageLink.indexOf('#'));
    }
    link.href = pageLink + "#" + id;
    var copyText = document.createElement('input');
    copyText.id = "hrefInput";
    document.body.appendChild(link);
    copyText.value = link.href;
    document.body.appendChild(copyText);
    var copyTextarea = document.getElementById('hrefInput');
    // copyTextarea.focus({preventScroll: true});
    copyTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(link);
    document.body.removeChild(copyText);
}

var disappear = function(){
    return $(document).height() - (800 +  $(window).height());
};

$(function(){
    $(window).scroll(function(){
        if($(this).scrollTop() >= disappear()){
            $('#portamento_container').fadeOut();
        }
        else{
            $('#portamento_container').fadeIn();
        }
    });
});

jQuery(document).ready(function(){
    var $ = jQuery;
    var $carousel = $('.sortiment-slider > .swiper-slide-active');
    $carousel.append("<li class='indicator-text'></li>");
});


(function($) {

    $.fn.visible = function(partial) {

        var $t            = $(this),
            $w            = $(window),
            viewTop       = $w.scrollTop(),
            viewBottom    = viewTop + $w.height(),
            _top          = $t.offset().top,
            _bottom       = _top + $t.height(),
            compareTop    = partial === true ? _bottom : _top,
            compareBottom = partial === true ? _top : _bottom;

        return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

    };
    $(window).scroll(function(event) {

        $("#footer").each(function(i, el) {
            var el = $(el);
            if (el.visible(true)) {
                $('.nav-container-icons').addClass('hide-on-footer');
            } else {
                $('.nav-container-icons').removeClass('hide-on-footer');
            }
        });

    });

})(jQuery);(function($) {

    $.fn.visible = function(partial) {

        var $t            = $(this),
            $w            = $(window),
            viewTop       = $w.scrollTop(),
            viewBottom    = viewTop + $w.height(),
            _top          = $t.offset().top,
            _bottom       = _top + $t.height(),
            compareTop    = partial === true ? _bottom : _top,
            compareBottom = partial === true ? _top : _bottom;

        return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

    };
    $(window).scroll(function(event) {
        if ( $('.observed-nav-container').length ) {
         var el = $(".observed-nav-container").offset().top  - $(window).scrollTop();
        }


        if (window.matchMedia("(max-width: 576px)").matches) {
            $(".event-container").each(function() {

                if (el <= 50) {
                    $('.event-sticky-container').addClass('show');
                }

                else {
                    $('.event-sticky-container').removeClass('show');
                }
            });
        }

        else if (window.matchMedia("(max-width: 768px)").matches) {
            $(".event-container").each(function() {

                if (el <= 60) {
                    $('.event-sticky-container').addClass('show');
                }

                else {
                    $('.event-sticky-container').removeClass('show');
                }
            });
        }

        else $(".event-container").each(function() {

            if (el <= 75) {
                $('.event-sticky-container').addClass('show');
            }

            else {
                $('.event-sticky-container').removeClass('show');
            }
        });

    });
    $(window).scroll(function(event) {

        $(".observed-nav-container").each(function(i, el) {
            var el = $(el);
            if (el.visible(true)) {

                $('.mod_jumptonav').addClass('jump-fixed');
            }else{
                $('.mod_jumptonav').removeClass('jump-fixed');
            }
        });

    });

})(jQuery);
