// ACCORDIONS

$(document).ready(function () {

    lazyload();
    teaserWidth();
    person();
    pasteLinkTab();
    mapUc();
    filterToggle();
    filterClass();
    initScrollbar();
    initArticleScrollbar();
    initThreeColScrollbar();
    videoPlay();
    youtubeConsent()
    iosVw();
    replaceText();
    breadcrumbIcon();
    scrollMotion();
    textPosition();
    showReset();
    hashTab();
    mobileAccordion();
    closeSidebar();
    zahlerHeight();
    headlineSpan();
    sortimentSlider();
    mobileNavParallax();
    sameHeight();
    merchantNewWindow();
    moveIcon();
    videoPlayThumb();
    searchSidebar();

    if (navigator.userAgent.indexOf('Mac OS X') != -1) {
        $("body").addClass("is-mac");
    }

    $(".card").click(function () {
        $(".card").not($(this)).removeClass('active-acc');
        $(".card").not($(this)).children().removeClass('show');
        $(".card").not($(this)).find(".ficon").removeClass("icon-minus").addClass("icon-plus");
    });

    $('.checkbox_container.filter-group .checkbox').on("change", showReset);

    $(window).on('resize', function () {
        sameHeight();
        filterClass();
        replaceText();
        textPosition();
        zahlerHeight();
        initScrollbar();
        initThreeColScrollbar();
        sortimentSlider();
        mobileNavParallax();
        $(".toggle-fixed-mobile").click(function () {
            $(".parallax-nav nav").toggle();
        });
    });

    if (window.matchMedia("(max-width: 768px)").matches) {

        $(window).scroll(function () {
            if ($('.observed-nav-container').length) {
                var jumpVisible = $(".observed-nav-container");

                if (jumpVisible.visible(true)) {
                    $('.toggle-fixed-mob').addClass("show");
                } else {
                    $('.toggle-fixed-mob').removeClass("show");
                }
            }
        });


        //Close Jump Nav on li click
        $(".mod_jumptonav li").click(function () {
            $('.mod_jumptonav').css('display', 'none');
            $('.toggle-fixed-mob').find(".btn-toggle-nav").removeClass("icon-open-x").addClass("icon-close-x");
        });
    }
    $('.collapse').on('shown.bs.collapse', function () {
        $(this).parent().addClass('active-acc');
    });

    $('.collapse').on('hidden.bs.collapse', function () {
        $(this).parent().removeClass('active-acc');
    });

    // Add minus icon for collapse element which is open by default
    $(".collapse.show").each(function () {
        $(this).prev(".card-header").find(".ficon").addClass("icon-minus").removeClass("icon-plus");
    });

    // Toggle plus minus icon on show hide of collapse element
    $(".collapse").on('show.bs.collapse', function () {
        $(this).prev(".card-header").find(".ficon").removeClass("icon-plus").addClass("icon-minus");
    }).on('hide.bs.collapse', function () {
        $(this).prev(".card-header").find(".ficon").removeClass("icon-minus").addClass("icon-plus");
    });

    var page = window.location.href;
    if (page.includes('#')) {
        var idToShow = page.substring(page.indexOf('#') + 1, page.length);
        $("#" + idToShow).parent().addClass('active-acc');
        $("#" + idToShow).addClass('show');
        var fixUrl = page.substring(0, page.indexOf('#'));
        history.pushState({
            id: ''
        }, "...", fixUrl);
    }

    //aos-init
    AOS.init({
        once: true,
    });

    $('.nav-container.nav-container-icons').clone().appendTo('.section-header-anim');
    $('.nav-container.nav-container-icons:first-child').clone().appendTo('.section-animation');
    $('.nav-container.nav-container-icons').first().hide();
    $('.nav-container.nav-container-icons').eq(1).addClass('default-nav');
    $('.nav-container.nav-container-icons').slice(2).addClass('clone-nav');


    //scrollbar width for correct 100vw
    function _calculateScrollbarWidth() {
        document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
    }

    // recalculate on resize
    window.addEventListener('resize', _calculateScrollbarWidth, false);
    // recalculate on dom load
    document.addEventListener('DOMContentLoaded', _calculateScrollbarWidth, false);
    // recalculate on load (assets loaded as well)
    window.addEventListener('load', _calculateScrollbarWidth);


    // Reset form on modal after submit
    $('.modal-form').on('hidden.bs.modal', function () {
        if ($('.modal-form .confirmation-message').length) {
            location.reload();
        }
    });

    if ($('.gutex-form:not(".gutex-external-form")').length) {
        $.urlParam = function (name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
            if (results == null) {
                return null;
            }
            return decodeURI(results[1]) || 0;
        }
        const product = $.urlParam('product');

        if (product && product.length) {
            var $productName = decodeURIComponent($.urlParam('product'));
            $('.gutex-form .form__text-input.xxlarge').val($('.gutex-form textarea.form__text-input.xxlarge').val() + 'Ich interessiere mich für Produkt ' + $productName + ' und wünsche ein Angebot.');
        }

    }

});

function zahlerHeight() {
    if ($('.co2-zahler').length) {
        var elDist = $('.zahler').offset().top;
        var zahlerDist = $('.zahler-tabs').offset().top;
        var dist = zahlerDist - elDist;

        var zahlerTotalHeight = $(".zahler-tabs-container").outerHeight();

        var redHeight = $(".zahler-tabs-container .red-background").outerHeight();

        var whiteHeight = zahlerTotalHeight - redHeight;

        var total = dist + whiteHeight;

        var img = $('.zahler .img-bg');
        img.css('height', total);
    }
}

// Tooltip TABLES
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})


function copyLink(id) {

    var parent = $("#" + id).parentsUntil("div.ce_accordion");
    var card = parent[2];
    var divContent = $(card).children();
    var idToLink = divContent.children()[1].children[0].children[0].children[0].id;
    var link = document.createElement('a');
    var pageLink = window.location.href;
    if (pageLink.includes("#")) {
        pageLink = pageLink.substring(0, pageLink.indexOf('#'));
    }
    link.href = pageLink + "#" + idToLink;
    var copyText = document.createElement('input');
    copyText.id = "hrefInput";
    document.body.appendChild(link);
    copyText.value = link.href;
    document.body.appendChild(copyText);
    var copyTextarea = document.getElementById('hrefInput');
    copyTextarea.select();
    divContent.find('button')[0].innerHTML = "<i class='icon-check'></i> Link kopiert";
    document.execCommand('copy');
    setTimeout(function () {
        divContent.find('button')[0].innerHTML = "<i class='icon-link'></i> Link kopieren";
    }, 1000);
    document.body.removeChild(link);
    document.body.removeChild(copyText);
}

function copyLinkSmall(id) {
    var link = document.createElement('link');
    var parent = $("#" + id).parentsUntil("div.video-el");
    var videoLink = $("#" + id).parentsUntil(".videoLink");
    var ytLink = videoLink.find(".video-btn").attr("data-url");
    var dloadedDiv = $(parent[2]);
    var dloaded = $(dloadedDiv).children();
    var dDiv = $(dloaded[1])[0];
    $(dDiv).show();
    $(dDiv).fadeOut(2000).hide(0);
    var pageLink = window.location.href;
    if (videoLink.hasClass("video-play-item")) {
        link.href = "https://www.youtube.com/watch?v=" + ytLink;
    } else {
        if (pageLink.includes("#")) {
            pageLink = pageLink.substring(0, pageLink.indexOf('#'));
        }

        link.href = pageLink + "#" + id;
    }

    var copyText = document.createElement('input');
    copyText.id = "hrefInput";
    document.body.appendChild(link);
    copyText.value = link.href;
    document.body.appendChild(copyText);
    var copyTextarea = document.getElementById('hrefInput');
    copyTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(link);
    document.body.removeChild(copyText);
}

function copyMediaLink(id) {
    var parent = $("#" + id).parentsUntil("div.video-el");
    var dloadedDiv = $(parent[2]);
    var dloaded = $(dloadedDiv).children();
    var dDiv = $(dloaded[1])[0];
    $(dDiv).show();
    $(dDiv).fadeOut(2000).hide(0);
    var link = dloadedDiv.find(".media-link").attr("href");

    var copyText = document.createElement('input');
    copyText.id = "hrefInput";
    copyText.value = link;
    document.body.appendChild(copyText);
    var copyTextarea = document.getElementById('hrefInput');
    copyTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(copyText);

}

function pasteLinkTab() {
    $(window).on('load', function () {
        var url = document.location.href;
        var split = url.split('#')[1];
        const elements = $(`[id^="item-download-"]`)
        elements.each(function () {
            var blockId = $(this).attr("id");
            var parentTab = $(this).closest(".tab-pane");

            if (blockId.includes(split)) {
                if (!parentTab.hasClass('active')) {
                    parentTab.siblings().removeClass("active show");
                    parentTab.addClass("active show");
                    var navLi = $(".nav-tabs").find("li");
                    $(window).scrollTop($(this).offset().top - $('#header').height() - 100);
                    navLi.each(function () {
                        if (!$(this).hasClass('active')) {
                            $(this).siblings().removeClass("active");
                            $(this).addClass("active");
                        }
                    });
                }
            }
        })
    });
}


//video & video list

function videoPlay() {

    $('.video').parent().on('click', function () {
        if ($(this).children(".video").get(0).paused) {
            $(this).children(".video").get(0).play();
            $(this).children(".playpause").hide();
        } else {
            $(this).children(".video").get(0).pause();
            $(this).children(".playpause").show();
        }

    });

    $('.video-desc-play').each(function () {
        var playicon = $(this).find('.play-icon');
        var pauseicon = $(this).find('.pause-icon');
        var vid = $(this).find('.desc-video');
        var videoel = $(this).find('.video-el');
        var opener = $(this).find('.video-btn');
        var modal = playicon.find('.video-btn').attr('data-target');
        var thumbnailImg = $(this).find('.video-el img')
        sessionStorage.clear();
        sessionStorage.setItem("thumbnailImg", thumbnailImg);

        window.addEventListener('UC_UI_INITIALIZED', function () {
            if (UC_UI.getServicesBaseInfo().find(data => (data.id === "BJz7qNsdj-7")).consent.status === false) {
                $(".video-el").addClass("no-consent");
                $(".yt-uc").parent().addClass("no-youtube-consent");
                $(this).find('.video-el img').attr('data-src', '')
                thumbnailImg.remove();
            } else {
                $(".video-el").removeClass("no-consent");
                $(".yt-uc").parent().removeClass("no-youtube-consent");
                sessionStorage.getItem('thumbnailImg');
            }

            window.addEventListener('UC_UI_CMP_EVENT', (data) => {
                if (data.detail.type === "DENY_ALL" || data.detail.type === "SAVE" || data.detail.type === "SAVE" || data.detail.type === "ACCEPT_ALL") {
                    location.reload();
                }
            });
        });


        if ($(vid).length != 0) {
            playicon.on('click', function () { // assign an event listener only for the current opener
                $('body').addClass('modal-open video-mod');
                $modalId = $(this).children().data('target');
                $(modal).addClass('visible').fadeIn(200);

                if (UC_UI.getServicesBaseInfo().find(data => (data.id === "BJz7qNsdj-7")).consent.status === false) {
                    $(".yt-uc").parent().addClass("no-youtube-consent");
                } else {
                    $(".yt-uc").parent().removeClass("no-youtube-consent");

                    if ($($modalId).hasClass('visible')) {
                        var videoId = $(this).find('video')[0].id;
                        $('#' + videoId)[0].play();
                    } else {
                        var videoId = $(this).find('video')[0].id;
                        $('#' + videoId)[0].pause();
                    }
                    $('.close, .modal-container').on('click', function () {
                        $('body').removeClass('modal-open video-mod');
                        $($modalId).removeClass('visible').fadeOut(200);
                        var videoId = $($modalId).find('video')[0].id;
                        $('#' + videoId)[0].pause();
                    });
                }

                $('.close, .modal-container').on('click', function () {
                    $('body').removeClass('modal-open video-mod');
                    $(modal).removeClass('visible').fadeOut(200);
                    var videoId = $($modalId).find('video')[0].id;
                    $('#' + videoId)[0].pause();
                });

            });
        } else {
            $(pauseicon).on('click', function () {
                videoel.find('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                pauseicon.hide();
                playicon.show();
            });
            var $modalId = $(this)[0].nextElementSibling.id;
            var $videoSrc;
            $(opener).on('click', function () {
                $('body').addClass('modal-open video-mod');

                $(modal).addClass('visible').fadeIn(200);

                if (UC_UI.getServicesBaseInfo().find(data => (data.id === "BJz7qNsdj-7")).consent.status === false) {
                    $(".yt-uc").addClass("active");
                    $(".open-uc").addClass("active");
                } else {
                    $(".yt-uc").removeClass("active");
                    $(".open-uc").removeClass("active");
                    $videoSrc = $(this).data("src");
                    if ($('#' + $modalId).hasClass('visible')) {
                        var videoId = $('#' + $modalId).find('iframe')[0].id;
                        $('#' + videoId).attr('src', $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
                    } else {
                        var videoId = $('#' + $modalId).find('iframe')[0].id;
                        $('#' + videoId).attr('src', $videoSrc);
                    }

                    $('.close, .modal-container').on('click', function () {
                        $('body').removeClass('modal-open video-mod');
                        $('#' + $modalId).removeClass('visible').fadeOut(200);
                        var videoId = $('#' + $modalId).find('iframe')[0].id;
                        $('#' + videoId).attr('src', $videoSrc);
                    });

                }

                var cookieButton = $(".no-youtube-consent .open-uc")
                cookieButton.each(function () {
                    $(this).on('click', function () {
                        UC_UI.showSecondLayer("BJz7qNsdj-7");
                    });
                })

                $('.close, .modal-container').on('click', function () {
                    $('body').removeClass('modal-open video-mod');
                    $(modal).removeClass('visible').fadeOut(200);
                    var videoId = $('#' + $modalId).find('iframe')[0].id;
                    $('#' + videoId).attr('src', $videoSrc);
                });
            });
        }
    });
}

function youtubeConsent() {
    window.addEventListener('UC_UI_INITIALIZED', function () {
        youtubeConsentCall();
        friendlyCaptchaConsent();
        window.addEventListener('UC_UI_CMP_EVENT', (data) => {
            if (data.detail.type === "DENY_ALL" || data.detail.type === "SAVE" || data.detail.type === "SAVE" || data.detail.type === "ACCEPT_ALL") {
                location.reload();
            }
        });
    });
}

function youtubeConsentCall() {
    var iframe = $("iframe");
    if (UC_UI.getServicesBaseInfo().find(data => (data.id === "BJz7qNsdj-7")).consent.status === false) {
        iframe.each(function () {
            var src = $('[src^="https://www.youtube.com/"]');
            var value = $(this).attr('src');
            if (src.length > 0) {
                this.setAttribute('src', '');
                this.setAttribute('data-src', value);
                this.classList.add("no-consent");
                $(this).parent().append('<button type="button" class="youtube-no-consent"><span>Cookie akzeptieren</span></button>');
                $(".youtube-no-consent").on('click', function () {
                    UC_UI.showSecondLayer("BJz7qNsdj-7");
                });
            }
        })
    } else {
        iframe.each(function () {
            var src = $('[src^="https://www.youtube.com/"]');
            var value = $(this).attr('src');
            if (src.length > 0) {
                this.setAttribute('data-src', '');
                this.setAttribute('src', value);
                this.classList.remove("no-consent");
                var consent = $(this).find(".youtube-no-consent");
                if (consent.length > 0) {
                    $(this).remove(consent);
                }
            }
        })
    }
}
function friendlyCaptchaCallBack (solution) {
    var element = $(".form-frc-captcha")
    element.each(function () {
        $(this).parents("form").find(".sendForm")[0].removeAttribute("disabled");
    })
}
function friendlyCaptchaConsent() {
    var form = $("form");
    var element = $(".form-frc-captcha")
    element.each(function () {
        $(this).parents("form").find(".sendForm")[0].setAttribute("disabled","disabled");
    })
    if (UC_UI.getServicesBaseInfo().find(data => (data.id === "WXuCIEBGA")).consent.status === false) {
        form.each(function () {
           $(this).find(".form-frc-captcha-wrapper").html('<div class="form-frc-no-captcha mt-3" style="color: #E94739FF; border-bottom: #E94739FF; cursor: pointer"><span>Cookie akzeptieren</span></div>')
                $(".form-frc-no-captcha").on('click', function () {
                    UC_UI.showSecondLayer("WXuCIEBGA");
                });
        })
    }
    if (UC_UI.getServicesBaseInfo().find(data => (data.id === "Hko_qNsui-Q")).consent.status === false) {
        form.each(function () {
           $(this).find(".g-recaptcha").html('<div class="form-frc-no-captcha no-recaptcha mt-3" style="color: #E94739FF; border-bottom: #E94739FF; cursor: pointer"><span>Cookie akzeptieren</span></div>')
                $(".form-frc-no-captcha.no-recaptcha").on('click', function () {
                    UC_UI.showSecondLayer("Hko_qNsui-Q");
                });
        })
    }

}
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// Limit Character
var limit = $('#limit-char');
limit.text(limit.text().substring(0, 500))

/* Hotspot */
$(document).ready(function () {

    if ($('.responsive-hotspot-wrap').length > 0) {
        $('.responsive-hotspot-wrap').hotSpot({
            bindselector: 'click'
        });
    }

    $('#load-more-results').on("click", function () {
        var ajaxsource = $('#search').data('ajax-source').split("?").pop();
        var searchTerm = $('#search').val();
        var offset = $('#search-results-container .search-counter-current').data('offset');
        var allResults = $('#search-results-container .search-counter-all').data('offset');

        $.ajax({
            type: 'GET',
            url: '/flowpack/ajaxsearch',
            data: ajaxsource + '*' + searchTerm + '*' + '&offset=' + offset + '&wrap=true',
            dataType: 'html',
            cache: false,
            success: function (nodes) {
                let root = $(nodes).first('div');
                $(root).children('div').each(function (index, result) {
                    $("#search-result-list").append('<li>' + result.innerHTML + '</li>');
                    $("#search-result-list .breadcrumb").each(function () {
                        var text = $(this).html();
                        $(this).html(text.replaceAll(' /', '<i class="icon-chevron-right"></i>'));
                    });
                });
                $('#search-results-container .search-counter-current').data('offset', offset + 3);
                $('#search-results-container .search-counter-current').html(offset + 3)

            }
        })

        if ((offset - allResults) >= -3) {
            $('#load-more-results').hide();

            var currentRes = $("#search-results-container .search-counter-all")[0].innerText;
            $("#search-results-container .search-counter-current").remove();
            $("#search-results-container .currentRes").html(currentRes);
        }
    });
});

$('.tabs').each(function () {

    var navtab = $(this).find('.nav-tabs li a');
    var navtabLi = $(this).find('.nav-tabs li');
    $(navtab).on('click', function () {
        $(navtabLi).removeClass("active");
        $(this).parent().addClass("active");
    });
});

//
// window.jQuery = window.$ = require("/Js/jquery-3.5.1.min");
// require("/Js/velocity.min");
// Optional: If you're using the UI pack, require it after Velocity. (You don't need to assign it to a variable.)
function addClicked(id) {
    for (var i = 0; i < 3; i++) {
        var elLeft = document.getElementsByClassName('sortiment-left')[i];
        var elRight = document.getElementsByClassName('sortiment-right')[i];
        var house = document.getElementsByClassName('smaller-circle')[i];
        var circle = document.getElementsByClassName('larger-circle')[i];
        var textSystem = document.getElementsByClassName('system-info')[i];
        var textProduct = document.getElementsByClassName('product-info')[i];
        var productExtra = document.getElementsByClassName('product-extra')[i];
        var systemExtra = document.getElementsByClassName('system-extra')[i];
        var clickedEl = document.getElementById(id);
        clickedEl.classList.add('clicked');
        circle.classList.add('make-smaller');
        if (elLeft.classList.contains('clicked')) {
            house.classList.add('move-left');
            textSystem.classList.add('system-down');
            productExtra.classList.add('product-extra-up');
            productExtra.querySelector('.swiper-container').swiper.slideTo(0, 0);
            productExtra.querySelector('.swiper-slide').querySelector('.slide-image').classList.add('before-progress-bar');
        }
        if (elRight.classList.contains('clicked')) {
            house.classList.add('move-right');
            textProduct.classList.add('product-down');
            systemExtra.classList.add('system-extra-up');
            systemExtra.querySelector('.swiper-container').swiper.slideTo(0, 0);
            systemExtra.querySelector('.swiper-slide').querySelector('.slide-image').classList.add('before-progress-bar');
        }
    }

}

function centerHouse() {
    for (var i = 0; i < 3; i++) {
        var elLeft = document.getElementsByClassName('sortiment-left')[i];
        var elRight = document.getElementsByClassName('sortiment-right')[i];
        var house = document.getElementsByClassName('smaller-circle')[i];
        var circle = document.getElementsByClassName('larger-circle')[i];
        var textSystem = document.getElementsByClassName('system-info')[i];
        var textProduct = document.getElementsByClassName('product-info')[i];
        var productExtra = document.getElementsByClassName('product-extra')[i];
        var systemExtra = document.getElementsByClassName('system-extra')[i];

        circle.classList.remove('make-smaller');
        if (house.classList.contains('move-left')) {
            house.classList.remove('move-left')
        }
        if (house.classList.contains('move-right')) {
            house.classList.remove('move-right');
        }
        if (elLeft.classList.contains('clicked')) {
            elLeft.classList.remove('clicked');
            textSystem.classList.remove('system-down');
            productExtra.classList.remove('product-extra-up');
            productExtra.querySelector('.swiper-container').swiper.slideTo(0, 0);
            productExtra.querySelector('.swiper-slide').querySelector('.slide-image').classList.remove('before-progress-bar')
        }
        if (elRight.classList.contains('clicked')) {
            elRight.classList.remove('clicked');
            textProduct.classList.remove('product-down');
            systemExtra.classList.remove('system-extra-up');
            systemExtra.querySelector('.swiper-container').swiper.slideTo(0, 0);
            systemExtra.querySelector('.swiper-slide').querySelector('.slide-image').classList.remove('before-progress-bar')
        }
    }
}


function sameHeight() {
    $('.row:not(.filter):not(.product)').each(function () {

        $('.gutex-teaser-content', this).each(function () {
            $(this).css('height', 'auto');
        });

        var maxHeight = 0;
        $('.gutex-teaser-content', this).each(function () {
            if ($(this).outerHeight() > maxHeight) {
                maxHeight = $(this).outerHeight();
            }

        });
        $('.gutex-teaser-content', this).each(function () {
            $(this).outerHeight(maxHeight);
        });

        //Reference content same height

        $('.referenzen-item .product-tiles-content', this).each(function () {
            $(this).css('height', 'auto');
        });

        var maxHeight = 0;
        $('.referenzen-item .product-tiles-content', this).each(function () {
            if ($(this).outerHeight() > maxHeight) {
                maxHeight = $(this).outerHeight();
            }

        });
        $('.referenzen-item .product-tiles-content', this).each(function () {
            $(this).outerHeight(maxHeight);
        });

    });
}

function teaserWidth() {
    $('.col-md-8:has(".contenance-gutex-content-imagetextbutton .style-5")').addClass('md10');
    $('.cols-2-8-2:has(".col-md-8 .contenance-gutex-content-imagetextbutton .style-5")').addClass('cols-md10');
}


function filterToggle() {
    /* Filter toggle */
    $(".filter-show-button").click(function () {
        $(".filter-elements").slideToggle("slow", function () {
            $('.filter-show-button').toggleClass("closed");
        });
    });

    if ($(window).width() <= 992) {
        $('.filter-show-button').addClass('closed');
    }
}

function filterClass() {
    if ($(window).width() <= 992) {

        if ($(".filter-elements").css('display') == 'none') {
            $('.filter-show-button').addClass('closed');
        } else {
            $('.filter-show-button').removeClass('closed');
        }

    } else {
        $('.filter-show-button').removeClass('closed');
        $('.filter-elements').css('display', 'block');
    }
}

// Init SimpleBar for custom scrollbar
function initScrollbar() {
    $('.ce_table').each(function (index, el) {
        new SimpleBar(el), {
            autoHide: false,
            forceVisible: true
        }
    });

    $('.neos-nodetypes-text figure.table').each(function (index, el) {
        new SimpleBar(el), {
            autoHide: false,
            forceVisible: true
        }
    });
}

function initArticleScrollbar() {
    if (window.matchMedia("(min-width: 576px) and (max-width: 992px)").matches) {
        var articles = $(".contenance-gutex-content-articles");

        if ($(articles).find('article.col-md-4').length !== 0) {
            $(articles).each(function (index, el) {
                new SimpleBar(el), {
                    autoHide: false,
                    forceVisible: true
                }
            });
        }
    }
}

function initThreeColScrollbar() {
    if (window.matchMedia("(min-width: 576px) and (max-width: 992px)").matches) {
        var col = $(".neos-nodetypes-columnlayouts-threecolumn");

        if ($(col).find('.col-md-4').length > 0) {
            $(".col-md-4").parent(".neos-nodetypes-columnlayouts-threecolumn").each(function (index, el) {
                new SimpleBar(el), {
                    autoHide: false,
                    forceVisible: true
                }
            });
        }
    }
}

function iosVw() {
// iOS Viewhight

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
}

//Change search results breadcrumb icon
function breadcrumbIcon() {
    $("#search-result-list .breadcrumb").each(function () {
        var text = $(this).html();
        $(this).html(text.replaceAll(' /', '<i class="icon-chevron-right"></i>'));
    });
}

function replaceText() {
    if (window.matchMedia("(max-width: 768px)").matches) {
        $(".event-sticky-container.show .gutex-event-detail").each(function () {
            var text = $(this).html();
            $(this).html(text.replaceAll('dena-Unterrichtseinheiten:', 'dena-UE:'));
        });
    } else if (window.matchMedia("(min-width: 769px)").matches) {
        $(".event-sticky-container.show .gutex-event-detail").each(function () {
            var text = $(this).html();
            $(this).html(text.replaceAll('dena-UE:', 'dena-Unterrichtseinheiten:'));
        });
    }
}

//Scroll Motion init
function scrollMotion() {
    if (window.matchMedia("(min-width: 576px)").matches) {
        let scrollMotion = new ScrollMotion('.scroll-motion', {
            speedY: -1,
        });
        scrollMotion;
    }
}

//Align text on top when bigger than image
function textPosition() {
    $('.fix-columns-height').each(function () {
        if ($('>div', this).has(".contenace-gutex-image")) {
            var maxHeight = ($('>div', this).has(".contenace-gutex-image")).height();

            $('>div', this).each(function () {
                if ($(this).outerHeight() > maxHeight) {
                    $(this).parent().addClass('text-bigger');
                } else {
                    $(this).parent().removeClass('text-bigger');
                }
            });
        }
    });

}

//Show filter reset
function showReset() {
    if ($('.checkbox_container.filter-group .checkbox:checked').length > 0) {
        $('.filter .filter-reset').css('display', 'flex');
    } else {
        $('.filter-elements .filter-reset').css('display', 'none');
    }
}

function hashTab() {
    // Open a tab using hashes in url
    function onHashChange() {
        var hash = window.location.hash;

        if (hash) {
            $(`[data-toggle="tab"][href="${hash}"]`).trigger('click');
            var header = $('#header').height();
            var str = hash.substring(1);
            var hashOff = document.getElementById(str);
            var hashOffset = $(hashOff).offset().top;
            $(window).scrollTop(hashOffset - header);
        }
    }

    window.addEventListener('hashchange', onHashChange, false);
    onHashChange();

    // Open a tab from link in same page
    "use strict";
    $('p a').bind('click', function (event) {
        if (this.pathname == window.location.pathname &&
            this.protocol == window.location.protocol &&
            this.host == window.location.host) {

            event.preventDefault();
            $('[data-toggle="tab"][href="' + this.hash + '"]').trigger('click');
            $(window).scrollTop($('[data-toggle="tab"][href="' + this.hash + '"]').offset().top - $('#header').height());

        }
    });
}

function person() {
    var person = $('div[data-id="person-list"]');
    var parent = person.parent();

    person.each(function () {
        parent.addClass("person-parent");
    });
}

function mobileAccordion() {

    $('.col-lg-3.transform-accordion > div:first-child').click(function (event) {

        if (window.matchMedia("(max-width: 768px)").matches) {
            event.preventDefault()

            $target = $('.col-lg-3.transform-accordion > div:not(:first-child)');
            if (!$(this).hasClass('active')) {
                $(this).parent().addClass('openedAccordion');
                $(this).addClass('active');
                $target.addClass('active').slideDown();
            } else {
                $(this).parent().removeClass('openedAccordion');
                $(this).removeClass('active');
                $target.removeClass('active').slideUp();
            }
        }
    });

}

function closeSidebar() {
    var faqLink = $(".faq-sidebar-element").find("a");
    faqLink.click(function () {
        $('.gutex-support-sidebar').removeClass("opened");
        $('.gutex-sticky-button').removeClass("active");
        $('.page-level-3').removeClass("overflow");
    });
}

function headlineSpan() {
    if ($('.zahler-container h2').length) {
        var zahler = $('.zahler-container h2');
        var headlineDiv = zahler.html();
        headlineDiv = headlineDiv
            .replace(/<div>/g, '<span>')
            .replace(/<\/div>/g, '</span>');
        zahler.html(headlineDiv);
    }
}

function sortimentSlider() {
    if ($(".sortiment-element .swiper-container .swiper-slide").length == 1) {
        $('.swiper-wrapper').addClass("disabled");
    }

    // Opened title position for mobile
    if ($(".sortiment-element").length) {
        if (window.matchMedia("(max-width: 576px)").matches) {
            $('.sortiment-element').each(function () {
                var sortiment = $(this);
                var sortimentHeight = $(this).height();

                // Systemlosungen slides
                var systemlosungenHeight = $(this).find('.systemlosungen-slider .info-container').height();
                var diffHeightSl = systemlosungenHeight - sortimentHeight / 2;
                var leftHeightSl = sortimentHeight / 2 - diffHeightSl;

                var topSl = diffHeightSl + leftHeightSl / 2 - (($(this).find('.sortiment-right .info-child').height()) / 2);
                var topSlIcon = sortimentHeight - leftHeightSl / 2 - 45 / 2;

                var producteHeight = $(this).find('.produkte-slider .info-container').height();
                var diffHeightP = producteHeight - sortimentHeight / 2;
                var leftHeightP = sortimentHeight / 2 - diffHeightP;

                var topP = leftHeightP / 2 - (($(this).find('.sortiment-left .info-child').height()) / 2);
                var topPIcon = leftHeightP / 2 - 45 / 2;

                $(sortiment).find('.sortiment-right').click(function () {
                    $(sortiment).find('.product-down').attr('style', 'top:' + topSl + 'px!important');
                    var iconElement = $(sortiment).find('.move-right');
                    iconElement.get(0).style.setProperty('--top', topSlIcon + 'px');
                    var topSlider = $(sortiment).find('#productSlider');
                    topSlider.get(0).style.setProperty('--topSlide', leftHeightP + 'px');
                });

                $(sortiment).find('.sortiment-left').click(function () {
                    $(sortiment).find('.system-down').attr('style', 'top:' + topP + 'px!important');
                    var iconElement = $(sortiment).find('.move-left');
                    iconElement.get(0).style.setProperty('--top', topPIcon + 'px');
                    var topSlider = $(sortiment).find('#productSlider');
                    topSlider.get(0).style.setProperty('--topSlide', leftHeightP + 'px');
                });


                $(sortiment).find('.close-button').click(function () {
                    $(sortiment).find('.system-info').removeAttr("style")
                    $(sortiment).find('.product-info').removeAttr("style")
                    $('.sortiment-element #productSlider').removeAttr("style")
                });


                $(sortiment).parents('.nav-item').click(function () {
                    $(sortiment).find('.system-info').removeAttr("style")
                    $(sortiment).find('.product-info').removeAttr("style")
                    $('.sortiment-element #productSlider').removeAttr("style")
                });
            });
        } else {
            $('.sortiment-element').each(function () {
                var sortiment = $(this);
                $(".sortiment-right").click(function () {
                    if ($(this).hasClass("clicked")) {
                        $('.product-info').removeAttr("style");
                    }
                });
                $(".sortiment-left").click(function () {
                    if ($(this).hasClass("clicked")) {
                        $('.system-info').removeAttr("style");
                    }
                });
            });
        }
    }
}

// Enable captcha
function enableBtn() {
    $("form").each(function () {
        $(this).find(':input[type="submit"]').prop('disabled', false);
    });
}

// Remove header from modal form when message shown
$("form").each(function () {
    $(this).submit(function (e) {
        var existCondition = setInterval(function () {

            if ($('.modal-form.show .confirmation-message').length) {

                clearInterval(existCondition);
                $('.modal-form.show .confirmation-message').parents('.modal-dialog').addClass('messageShown');
            } else {
                $('.modal-form.show .confirmation-message').parents('.modal-dialog').removeClass('messageShown');
            }
        }, 100); // check every 100ms
    });
});


//Scroll to header of opened accordion
$('.collapse').on('shown.bs.collapse', function (e) {

    var $header = $('#header');
    var $card = $(this).closest('.ce_accordion.content-block .card');
    if ($card.length != 0) {
        if (!$('.ce_accordion.content-block').hasClass("search_toggler")) {
            $('html,body').animate({
                scrollTop: $card.offset().top - $header.height()
            }, 500);
        }
    }
});

// Mobile navigation parallax page
function mobileNavParallax() {
    if (window.matchMedia("(max-width: 768px)").matches) {
        $(".parallax-nav nav").addClass("mobile-nav");
        $(".toggle-fixed-mobile").click(function () {
            $(".parallax-nav nav").toggle();
        });
        //Close Jump Nav on li click
        $(".parallax-nav nav li").click(function () {
            $(".parallax-nav nav").toggle();
            $('.toggle-fixed-mobile').find(".btn-toggle-nav").removeClass("icon-open-x").addClass("icon-close-x");
        });

    } else {
        if ($(".parallax-nav nav").hasClass("mobile-nav")) {
            $(".parallax-nav nav").removeClass("mobile-nav");
        }
    }
}

// Merchant Search Web pages New Tab

function merchantNewWindow() {
    var dealer = $("#dealerAccordions");
    var buttons = dealer.find(".action-link a");
    buttons.each(function () {
        var webPage = $(this).find(".icon-language");
        if (webPage.length > 0) {
            this.setAttribute('target', '_blank')
        }
    });

}


// ImageTextButton Element Icon

function moveIcon() {
    var elements = $(".style-11 .secondary-button");
    elements.each(function () {
        var elSpan = $(this).find("div p a span");
        var elIcon = $(this).find("i");
        if (elIcon.length > 0) {
            elements.remove(elIcon);
            elSpan.prepend(elIcon);
        }
    });

}

// Map Usercentrics
function mapUc() {
    window.addEventListener('UC_UI_INITIALIZED', function () {
        if (UC_UI.getServicesBaseInfo().find(data => (data.id === "S1pcEj_jZX")).consent.status === false) {
            $(".map-uc").addClass("active");
            $(".map-uc").parent().addClass("map-no-consent");

            $(".map-uc").click(function () {
                UC_UI.showSecondLayer("S1pcEj_jZX");
            });
        } else {
            $(".map-uc").removeClass("active");
            $(".map-uc").parent().removeClass("map-no-consent");
            $(".merchant-map-uc").removeClass("active");
        }

        $(".showDealers").click(function () {
            if (UC_UI.getServicesBaseInfo().find(data => (data.id === "S1pcEj_jZX")).consent.status === false) {
                $(".merchant-map-uc").addClass("active");
                $(".merchant-map-uc").click(function () {
                    UC_UI.showSecondLayer("S1pcEj_jZX");
                });
            } else {
                $(".merchant-map-uc").removeClass("active");
            }
        });

        window.addEventListener('UC_UI_CMP_EVENT', (data) => {
            if (data.detail.type === "DENY_ALL" || data.detail.type === "SAVE" || data.detail.type === "SAVE" || data.detail.type === "ACCEPT_ALL") {
                location.reload();
            }
        });
    });


}

//Video Play on Thumbnail
function videoPlayThumb() {
    var ytVideos = $(".youtube-video")
    ytVideos.each(function () {
        var thumbnailPlay = $(this).find(".video-el .playpause");
        var playBtn = $(this).find(".video-btn");
        thumbnailPlay.click(function () {
            playBtn.trigger("click");
        });
    });
}

//Search Sidebar
function searchSidebar() {
    var cards = $(".faq-section .card");
    cards.each(function () {
        var elements = $(this).find(".faq-sidebar-element");
        var searchInput = $(this).find(".support-search input");
        $(this).click(function () {
            if (!$(this).hasClass("active-acc")) {
                searchInput.val('');
                elements.css('display', 'block')
            }
        });
    });
}