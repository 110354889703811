$(document).ready(function () {
    let grandConstFilters = document.querySelectorAll('.konstruktionsfinder');
    if(grandConstFilters.length !== 0) {
        $('.konstruktionsfinder').each( function( i, grandConstFilter ){
            let $grandConstFilter = $(grandConstFilter);
            let allNodes = [];
            let allContent=[];
            var contentObj = [];
            let firstGroup = getFirstGroup();
            let secondGroup = getSecondGroup();
            let thirdGroup = getThirdGroup();
            let constCardContainer = getConstCardContainer();

            let constDetailFilterCount = grandConstFilter.querySelector('.detail-filter-count');
            let constDetailFilters = {};


            $(document).ready( function () {
                getItemsFromAPI();
                getContentFromAPI("/filterabletables.xml");
                getContentFromAPI("/filterabletables2.xml");
                getContentFromAPI("/filterabletables3.xml");
                getContentFromAPI("/filterabletables4.xml");
                getContentFromAPI("/filterabletables5.xml");
                getContentFromAPI("/filterabletables6.xml");
            });

            function getItemsFromAPI(){
                $.ajax({
                    type: 'GET',
                    url: '/constructionfinder/getConstructionData',
                    dataType: 'json',
                    cache: false,
                    beforeSend: function () {},
                    success: function(nodes) {
                        allNodes = nodes;
                    },
                    error: function () {}
                });
            }
            function getContentFromAPI(url){
                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function() {
                    if (this.readyState == 4 && this.status == 200) {
                        allContent =$(this.responseText).find('constructionelement');
                        contentObj =  getContentObj(allContent);
                    }
                };
                xhttp.open("GET", url, true);
                xhttp.send();

            }



            function getContentObj (allContent){
                $.each(allContent, function( i, data ){
                    let accordionId = data.id;
                    contentObj[accordionId] = data;
                });
                return contentObj;
            };

            $(document).on('click','.konstruktionsfinder .ce_accordion .title', function() {
                var acc = $(this).parent().parent().parent().parent().parent();
                var card = $(this).parentsUntil(".card")
                var $header = $('#header');
                if(acc.find('.ficon').hasClass('icon-plus')){
                    $grandConstFilter.find(".ce_accordion").not(acc).find(".card").removeClass('active-acc');
                    $grandConstFilter.find(".ce_accordion .card").not(acc).find('.ficon').removeClass('icon-minus').addClass('icon-plus');
                    acc.find(".ficon").removeClass('icon-plus').addClass('icon-minus');
                    acc.find('.card').addClass('active-acc');

                    acc.find('.konst-detail-filters .filter-select').prop("selectedIndex", 0);
                    console.log(acc.find('.konst-detail-filters .filter-select'))

                }else{
                    $grandConstFilter.find(".ce_accordion").not(acc).find(".card").removeClass('active-acc');
                    $grandConstFilter.find(".ce_accordion .card").not(acc).find('.ficon').removeClass('icon-minus').addClass('icon-plus');
                    acc.find(".ficon").removeClass('icon-minus').addClass('icon-plus');
                    acc.find(".card").removeClass('active-acc');
                }

                $grandConstFilter.find(".ce_accordion .card").not(acc).children().removeClass('show');
                $grandConstFilter.find(".ce_accordion").not(acc).find('.title').addClass('collapsed');


                Object.keys(contentObj).forEach(key => {

                    if(acc[0].id.split('_')[1] ==  key){
                        acc.find('.card-body').append(contentObj[key].cloneNode(true));
                    }
                });
                var $miniTables = grandConstFilter.querySelectorAll('.acc-filter');
                $miniTables.forEach(function (miniTable,miniIndex) {
                    $(function() {
                        var $container = $($miniTables[miniIndex]).isotope({
                            itemSelector : '.konst-filter-item',
                            layoutMode : 'vertical',
                            transitionDuration : 0,
                        });
                        var filters = {};
                        $($miniTables[miniIndex]).on('change','.konst-detail-filters', function(event) {
                            var $this = $(this);
                            var filterGroup = $this.attr('value-group');
                            filters[filterGroup] = event.target.value;//$this.find(':selected')[1].value;
                            var filterValue = '';
                            for (var prop in filters) {
                                filterValue += filters[prop];
                            }
                            $container.isotope({
                                filter : filterValue
                            });
                        });
                    });
                });
                $('.filter-select.acc-dropdown-select.selectpicker').selectpicker();

                $('html,body').animate({
                    scrollTop: card.offset().top - $header.height()
                }, 500);

            });

            $grandConstFilter.find('.outer-detail-filters').on('change', function (e) {
                var $select = $(e.target);
                var detailFilterGroup = $select.attr('value-group');
                constDetailFilters[detailFilterGroup] = e.target.value;
                //constDetailFilterValue = concatValues(constDetailFilters);
                updateConstFilterCount(constDetailFilterCount);

            });
            constCardContainer.classList.add("display-height-zero");
            constCardContainer.style.overflow = 'hidden';

            firstGroup.addEventListener('change', function() {

                let firstGroupVal = this.querySelector('select').value;
                let selectedIndex = this.querySelector('select').selectedIndex;

                if(selectedIndex !== 0 && secondGroup.classList.contains('disabled')) {
                    secondGroup.classList.replace("disabled", "enabled");
                }
                if(selectedIndex === 1 && secondGroup.classList.contains('enabled')) {
                    secondGroup.classList.replace("enabled", "disabled");
                }
                if( thirdGroup.classList.contains('enabled')) {
                    thirdGroup.classList.replace("enabled", "disabled");
                }
                if(selectedIndex === 0) {
                    constCardContainer.style.display = 'none';
                }
                else {
                    constCardContainer.style.display = 'block';
                }
                if(constCardContainer.classList.contains("display-height-full")) {
                   constCardContainer.classList.remove("display-height-full");
                }
                if(!constCardContainer.classList.contains("display-height-zero")) {
                    constCardContainer.classList.add("display-height-zero");
                }
                secondGroup.querySelector('select').selectedIndex = 0;
                thirdGroup.querySelector('select').selectedIndex = 0;
                $('option', secondGroup).filter(function(){
                    if (
                        $(this).attr('data-group') === firstGroupVal
                        || $(this).attr('data-group') === ''
                    ) {
                        if ($(this).parent('span').length) {
                            $(this).unwrap();
                        }
                    } else {
                        if (!$(this).parent('span').length) {
                            $(this).wrap( "<span>" ).parent().hide();
                        }
                    }
                });

                $(secondGroup).find('.selectpicker').selectpicker('refresh');
                $(thirdGroup).find('.selectpicker').selectpicker('refresh');

            });

            secondGroup.addEventListener('change', function() {
                let secondGroupVal = this.querySelector('select').value;
                var firstGroupVal = $(firstGroup).find(':selected')[0].value;
                var filteredItems1 = filterElements(allNodes,firstGroupVal,1 );
                removeOldItems();
                var filteredItems2 = filterElements(filteredItems1, secondGroupVal, 2);
                var displayItem = filterElements(filteredItems2, '',4);

                $(constCardContainer).find('.neos-contentcollection').append(displayItem);
                updateConstFilterCount(constDetailFilterCount);

                if(this.querySelector('select').selectedIndex !== 0 && thirdGroup.classList.contains('disabled')) {
                    thirdGroup.classList.replace("disabled", "enabled");
                }

                if(constCardContainer.classList.contains("display-height-full")) {
                   constCardContainer.classList.remove("display-height-full");
                }

                if(!constCardContainer.classList.contains("display-height-zero")) {
                    constCardContainer.classList.add("display-height-zero");
                }

               thirdGroup.querySelector('select').selectedIndex = 0;
                $('option', thirdGroup).filter(function(){
                    if (
                        $(this).attr('data-group') === secondGroupVal
                        || $(this).attr('data-group') === ''
                    ) {
                        if ($(this).parent('span').length) {
                            $(this).unwrap();
                        }
                    } else {
                        if (!$(this).parent('span').length) {
                            $(this).wrap( "<span>" ).parent().hide();

                        }
                    }
                });

                constCardContainer.classList.remove("display-height-zero");
                if(!constCardContainer.classList.contains("display-height-full")) {
                    setTimeout( function () {
                        constCardContainer.classList.add("display-height-full");
                    }, 375)
                }
                $(thirdGroup).find('.selectpicker').selectpicker('refresh');

            });

            thirdGroup.addEventListener('change', function() {
                let thirdGroupVal = this.querySelector('select').value;
                var firstGroupVal = $(firstGroup).find(':selected')[0].value;
                var secondGroupVal = $(secondGroup).find(':selected')[0].value;
                var filteredItems1 = filterElements(allNodes, firstGroupVal, 1);
                var filteredItems2 = filterElements(filteredItems1, secondGroupVal, 2);
                removeOldItems();
                var filteredItems3 = filterElements(filteredItems2, thirdGroupVal, 3);
                $(constCardContainer).find('.neos-contentcollection').append(filteredItems3);
                constCardContainer.classList.remove("display-height-zero");
                if(!constCardContainer.classList.contains("display-height-full")) {
                    setTimeout( function () {
                        constCardContainer.classList.add("display-height-full");
                    }, 375)
                }
            });


            function updateConstFilterCount(constElem) {
               var totalNr =  $(grandConstFilter.querySelector('.konst-katalog-filter .neos-contentcollection ')).children().length
               $(constElem).text(totalNr);
            }

            function getFirstGroup(){
                return grandConstFilter.querySelector('[id*="firstGroup"]' );
            }
            function  getSecondGroup(){
                return grandConstFilter.querySelector('[id*="secondGroup"]' );
            }
            function getThirdGroup(){
                return grandConstFilter.querySelector('[id*="thirdGroup"]' );
            }
            function getConstCardContainer(){
                return grandConstFilter.querySelector('.konst-katalog-filter');
            }

            function filterElements(bigArray, groupVal, id ){
               var result = [];
                $.each( $(bigArray), function( i, data ){
                    var filterVal;
                    var selectedVal;
                    switch (id){
                        case 3:
                             selectedVal = groupVal.replace('.', '');
                             filterVal= $(data).find('.card').attr('data-filterval').split(' ');
                            if(filterVal.includes(selectedVal)){
                                result.push(data);
                            }
                            break;
                        case 4:
                             filterVal= $(data).find('.card').attr('data-filterval').split(' ').filter(e =>  e);
                            if(filterVal.length==2){
                                result.push(data);
                            }
                            break;
                        default:
                             selectedVal = groupVal.replace('.', '');
                             filterVal= $(data).find('.card').attr('data-filterval').split(' ').filter(e =>  e);
                            if(filterVal.includes(selectedVal)){
                                result.push(data);
                            }
                    }
                });
                return result;
            }

            function removeOldItems() {
                $grandConstFilter.find('.konst-katalog-filter').children('.neos-contentcollection').empty();
            }

        });
    };
});