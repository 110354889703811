// init on after all dom has loaded
$(document).ready(function () {
    // get all the divs that have counters on the page
    var veryImportantDivs = document.querySelectorAll('.counter-blocks');
    var count = 0;

    var lastYearValueTons = 0;
    var actualYearValueTons = 0;
    var actualProgressPercentage = 0;
    var actualValueTon = 0;

    // after page has fully loaded, check which elements are above the current scroll amount and do the progress calculations, as well as remove their event intersectionality observers
    window.addEventListener('load', function () {
        veryImportantDivs.forEach(function (veryImportantDiv, index) {
            if ((window.scrollY >= (window.pageYOffset + veryImportantDiv.getBoundingClientRect().top - 925))) {
                (function ($) {
                    $.fn.countTo = function (options) {
                        options = options || {};
                        return $(this).each(function () {
                            // set options for current element
                            var settings = $.extend({}, $.fn.countTo.defaults, {
                                from: $(this).data('from'),
                                to: $(this).attr('to'),
                                speed: $(this).data('speed'),
                                refreshInterval: $(this).data('refresh-interval'),
                                decimals: $(this).data('decimals')
                            }, options);
                            var specialRequest = String(settings.to);
                            specialRequest = specialRequest.replace(/[^0-9]/g, '');
                            settings.to = parseInt(specialRequest);
                            // how many times to update the value, and how much to increment the value on each update
                            var loops = Math.ceil(settings.speed / settings.refreshInterval),
                                increment = (settings.to - settings.from) / loops;

                            // references & variables that will change with each update
                            var self = this,
                                $self = $(this),
                                loopCount = 0,
                                value = settings.from,
                                data = $self.data('countTo') || {};

                            $self.data('countTo', data);

                            // if an existing interval can be found, clear it first
                            if (data.interval) {
                                clearInterval(data.interval);
                            }
                            data.interval = setInterval(updateTimer, settings.refreshInterval);

                            // initialize the element with the starting value
                            render(value);

                            function updateTimer() {
                                value += increment;
                                loopCount++;

                                render(value);

                                if (typeof (settings.onUpdate) == 'function') {
                                    settings.onUpdate.call(self, value);
                                }

                                if (loopCount >= loops) {
                                    // remove the interval
                                    $self.removeData('countTo');
                                    clearInterval(data.interval);
                                    value = settings.to;

                                    if (typeof (settings.onComplete) == 'function') {
                                        settings.onComplete.call(self, value);
                                    }
                                }
                            }

                            function render(value) {
                                var formattedValue = settings.formatter.call(self, value, settings);
                                $self.html(formattedValue);
                            }
                        });
                    };

                    $.fn.countTo.defaults = {
                        from: 0,               // the number the element should start at
                        to: 0,                 // the number the element should end at
                        speed: 1000,           // how long it should take to count between the target numbers
                        refreshInterval: 100,  // how often the element should be updated
                        decimals: 0,           // the number of decimal places to show
                        formatter: formatter,  // handler for formatting the value before rendering
                        onUpdate: null,        // callback method for every time the element is updated
                        onComplete: null       // callback method for when the element finishes updating
                    };

                    function formatter(value, settings) {
                        return value.toFixed(settings.decimals);
                    }
                }(jQuery));
                jQuery(function ($) {
                    // custom formatting example
                    $('.count-number').data('countToOptions', {
                        formatter: function (value, options) {
                            return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, '.');
                        }
                    });

                    // start all the timers
                    $('.timer').each(count);

                    function count(options) {
                        var $this = $(this);
                        options = $.extend({}, options || {}, $this.data('countToOptions') || {});
                        $this.countTo(options);
                    }
                });
                /* percentage calculation */
                progressCalculator(veryImportantDiv.parentElement);
                count = index + 1;
                observer.unobserve(veryImportantDiv);
            }
        });
    });

    function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    //Values in tons
    function getCalculationValuesTons(container) {
        lastYearValueTons = container.querySelector('.last-year').getAttribute('data-lastValue');
        actualYearValueTons = container.querySelector('.actual-year').getAttribute('data-actualValue');

        //remove german thausand separator
        lastYearValueTons = lastYearValueTons.replace('.', '');
        actualYearValueTons = actualYearValueTons.replace('.', '');
        //add eng decimal separator
        lastYearValueTons = lastYearValueTons.replace(',', '.');
        actualYearValueTons = actualYearValueTons.replace(',', '.');
    }

    //calculate progress of actual year based on days
    function calculateProgress(actualYear) {
        var progressPerDay = actualYearValueTons / 365;
        var today = new Date();
        var startOfThisYear = new Date('01/01/' + actualYear);
        var difference = today.getTime() - startOfThisYear.getTime();
        var totalDaysGone = Math.ceil(difference / (1000 * 3600 * 24));

        actualValueTon = Math.ceil(totalDaysGone * progressPerDay);
        actualProgressPercentage = Math.ceil((100 * actualValueTon) / lastYearValueTons);
    }

    function progressCalculator(container) {
        var currentYear = new Date().getFullYear();//display in textbox

        getCalculationValuesTons(container);
        calculateProgress(currentYear);

        //set actual year to text
        container.querySelector('.counter').querySelector('.box1').getElementsByTagName('span')[0].innerHTML = currentYear;

        var lastYear = currentYear - 1;
        container.querySelector('.goal-text').nextElementSibling.getElementsByTagName('span')[0].innerHTML = lastYear;
        var lastYearValueKg = lastYearValueTons * 1000;
        container.querySelector('.goalCarbon').innerHTML = formatNumber(lastYearValueKg);

        //convert to kg
        var actualYearValKg = actualValueTon * 1000;
        container.querySelector('.currentCarbon').innerText = actualYearValKg;
        container.querySelector('.currentCarbon').setAttribute('to', actualYearValKg);

        //calculate Environmental relief passenger car
        //1000 kg of Co2 = 6.000 km with PKW
        var PKW = Math.ceil((6000 * actualYearValKg) / 1000);
        container.querySelector('.entlastungVal').innerHTML = PKW;
        container.querySelector('.entlastungVal').setAttribute('to', PKW);

        //calculate circumnavigating the globe
        //1 “circumnavigating the globe” = 40.075 km with PKW
        var Erdumrundungen = Math.ceil(PKW / 40075);
        container.querySelector('.erdumrundungenVal').innerHTML = Erdumrundungen;
        container.querySelector('.erdumrundungenVal').setAttribute('to', Erdumrundungen);

        //progress bar
        var number = container.querySelector('.percent-number');
        var progressBar = container.querySelector('.real-progress');
        var width = actualProgressPercentage;
        number.textContent = actualProgressPercentage + ' %';
        var progressWidth = width + '%';
        $(progressBar).animate({
            width: progressWidth,
        }, 2500, function () {
        });
    }

    //one time listener for calculations and counters
    const callBackFn = function (entries, observer) {

        if (entries[0].isIntersecting === true) {

            (function ($) {
                $.fn.countTo = function (options) {
                    options = options || {};
                    return $(this).each(function () {
                        // set options for current element
                        var settings = $.extend({}, $.fn.countTo.defaults, {
                            from: $(this).data('from'),
                            to: $(this).attr('to'),
                            speed: $(this).data('speed'),
                            refreshInterval: $(this).data('refresh-interval'),
                            decimals: $(this).data('decimals')
                        }, options);
                        var specialRequest = String(settings.to);
                        specialRequest = specialRequest.replace(/[^0-9]/g, '');
                        settings.to = parseInt(specialRequest);
                        // how many times to update the value, and how much to increment the value on each update
                        var loops = Math.ceil(settings.speed / settings.refreshInterval),
                            increment = (settings.to - settings.from) / loops;

                        // references & variables that will change with each update
                        var self = this,
                            $self = $(this),
                            loopCount = 0,
                            value = settings.from,
                            data = $self.data('countTo') || {};

                        $self.data('countTo', data);

                        // if an existing interval can be found, clear it first
                        if (data.interval) {
                            clearInterval(data.interval);
                        }
                        data.interval = setInterval(updateTimer, settings.refreshInterval);

                        // initialize the element with the starting value
                        render(value);

                        function updateTimer() {
                            value += increment;
                            loopCount++;

                            render(value);

                            if (typeof (settings.onUpdate) == 'function') {
                                settings.onUpdate.call(self, value);
                            }

                            if (loopCount >= loops) {
                                // remove the interval
                                $self.removeData('countTo');
                                clearInterval(data.interval);
                                value = settings.to;

                                if (typeof (settings.onComplete) == 'function') {
                                    settings.onComplete.call(self, value);
                                }
                            }
                        }

                        function render(value) {
                            var formattedValue = settings.formatter.call(self, value, settings);
                            $self.html(formattedValue);
                        }
                    });
                };

                $.fn.countTo.defaults = {
                    from: 0,               // the number the element should start at
                    to: 0,                 // the number the element should end at
                    speed: 1000,           // how long it should take to count between the target numbers
                    refreshInterval: 100,  // how often the element should be updated
                    decimals: 0,           // the number of decimal places to show
                    formatter: formatter,  // handler for formatting the value before rendering
                    onUpdate: null,        // callback method for every time the element is updated
                    onComplete: null       // callback method for when the element finishes updating
                };

                function formatter(value, settings) {
                    return value.toFixed(settings.decimals);
                }
            }(jQuery));
            jQuery(function ($) {
                // custom formatting example
                $('.count-number').data('countToOptions', {
                    formatter: function (value, options) {
                        return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, '.');
                    }
                });

                // start all the timers
                $('.timer').each(count);

                function count(options) {
                    var $this = $(this);
                    options = $.extend({}, options || {}, $this.data('countToOptions') || {});
                    $this.countTo(options);
                }
            });
            /* percentage calculation */
            progressCalculator(entries[0].target.parentElement);
            observer.unobserve(entries[0].target);

        }
    }
    // observer
    const observer = new IntersectionObserver(callBackFn, {
        threshold: 0.1
    })
    // init observer for each div
    veryImportantDivs.forEach(function (veryImportantDiv) {
        observer.observe(veryImportantDiv);
    })
});
