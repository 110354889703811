// todo just test file - remove me later
// you can put here as much separated files as you want like in Neos folder
// the files in Shop folder will be compiled to Neos' build.js too - no need to implement build-shop.js to Neos

$(document).ready(function () {
    //Search
    $('input[type="search"]').on('input', function () {

        var inputText = $(this).val();
        $('input[type="search"]').val(inputText);

        if ($(this).val().length >= 3) {
            var suggestSource = $(this).data('suggest-source') + $(this).val();
            var ajaxSource = $(this).data('ajax-source') + $(this).val();
            var ajaxSourceUrl = $(this).data('ajax-source');
            var searchTherm = $(this).val();

            $('#search-results').addClass('active');
            $('#search-page-suggestions').parent().addClass('active');

            $.get(suggestSource, function (data) {

                var searchSugestion = data['completions'];

                function makeUL(searchSugestion) {
                    var list = document.createElement('ul');

                    function loadSuggestions() {
                        for (var i = 0; i < searchSugestion.length; i++) {
                            if (i < 10) {
                                // Create the list item
                                var item = document.createElement('li');
                                item.appendChild(document.createTextNode(searchSugestion[i]));
                                list.appendChild(item);
                                $(item).click(function () {
                                    var listText = $(this).html();
                                    $("input#search").val(listText);

                                    $.get(ajaxSourceUrl + listText, function (data) {
                                        $('.search-link').html(data);
                                        $('#search-page-links').html(data);

                                        var innerLinks = $('.search-link').children().children();
                                        for (var i = 0; i < innerLinks.length; i += 3) {
                                            innerLinks.slice(i, i + 3).wrapAll("<div class='search-quick-result'></div>");
                                        }
                                    });
                                });
                            }
                        }
                    }

                    loadSuggestions();

                    return list;

                }

                $('#search-results .search-suggestions ul').remove();
                $('#search-page-suggestions ul').remove();
                if (searchSugestion.length) {
                    $('#search-results .search-suggestions').append(makeUL(searchSugestion));
                    if ($('#search-page-suggestions').length) {
                        document.getElementById('search-page-suggestions').appendChild(makeUL(searchSugestion));
                    }
                }

            });

            $.get(ajaxSourceUrl + '*' + searchTherm + '*', function (data) {
                $('.search-link').html(data);
                $('#search-page-links').html(data);

                var innerLinks = $('.search-link').children().children();
                for (var i = 0; i < innerLinks.length; i += 3) {
                    innerLinks.slice(i, i + 3).wrapAll("<div class='search-quick-result'></div>");
                }
            });

        } else {
            $('#search-results').removeClass('active');
            $('#search-page-suggestions').parent().removeClass('active');
            return false;
        }
        $(document).on("click", function (e) {
            if ($(e.target).is(".gutex-search-page") === false) {
                $(".gutex-search-page").removeClass("active");
            }
        });

        $('.search-suggestion li').on('click', function () {
            var inputValue = $(this)[0].innerText;
            var inputField = $('#search');
            inputField.val(inputValue);

        })

    });

    $(function () {
        var href = $('.search-all').attr('href');
        $('#search').keyup(function () {
            var inputVal = $('#search').val();
            var allSearch = href + inputVal + "*"
            $('.search-all').attr('href', allSearch);
        });
    });

    //if no results
    if ($('#search-result-list').is(':empty')) {
        $('#load-more-results').hide();
    }

    // Search focus old ios
    function focusSearch() {
        $('#icon-search .icon-search').click(function () {
            $('.search-block.open input#search').focus();
            $('.search-block.open input#search').trigger('click');
        });
    }

    // Remove page scrolling when search is open
    function searchScroll() {
        var searchButton = $("#icon-search .icon-search");

        searchButton.click(function () {
            $("body").addClass("no-scroll");
        });
    }

    // Search Close
    function closeSearch() {
        var searchClose = $("#icon-search .icon-close");

        searchClose.click(function () {
            $('input#search').val('');
            $("body").removeClass("no-scroll");
            $(".search-block").removeClass("open");
            $("#icon-search").removeClass("active");
            $("#search-results").removeClass("active");
        })
    }

    //Search Enter
    function searchEnter() {
        $('#search').keypress(function (e) {
            var key = e.which;
            if (key === 13)  // the enter key code
            {
                $(".search-all")[0].click();
            }
        });
    }

    //Search Result Page Searchbar
    function searchPageBar() {
        var currentURL = window.location.href;
        var searchBar = $(".search-block");
        var searchInput = searchBar.find("input");
        var searchInputValue = searchInput.val();

        if (currentURL.indexOf("?search") > -1) {
            searchBar.addClass('open')
            $("#icon-search").addClass("active");

            if (searchInputValue.indexOf("*") > -1) {
                searchInputValue = searchInputValue.substring(1, searchInputValue.length-1);
                searchInput.attr('value', searchInputValue)
            }
        }
    }

    //Recaptcha Consent
    function recaptchaConsent() {
        window.addEventListener('UC_UI_INITIALIZED', function() {
            var form = $("form");
            if (UC_UI.getServicesBaseInfo().find(data => (data.id === "Hko_qNsui-Q")).consent.status === false) {
                form.each(function (){
                    var gRecaptcha = $(this).find(".recaptcha-consent")
                    gRecaptcha.addClass("active");
                    gRecaptcha.click(function () {
                        UC_UI.showSecondLayer("Hko_qNsui-Q");
                    });
                });

            } else {
                form.each(function (){
                    var gRecaptcha = $(this).find(".recaptcha-consent")
                    gRecaptcha.removeClass("active");
                });
            }
        });

        window.addEventListener('UC_UI_CMP_EVENT', (data) => {
            if (data.detail.type === "DENY_ALL" || data.detail.type === "SAVE" || data.detail.type === "SAVE" || data.detail.type === "ACCEPT_ALL") {
                location.reload();
            }
        });
    }

    focusSearch();
    searchScroll();
    closeSearch();
    searchEnter();
    searchPageBar();
    recaptchaConsent();
});