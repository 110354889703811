$(document).ready(function () {
    let grandDrawingFilters = document.querySelectorAll('.detailzeichnungskatalog-filter');
    if(grandDrawingFilters.length !== 0) {
        $('.detailzeichnungskatalog-filter').each( function( i, grandDrawingFilter ){
            let $grandDrawingFilter = $(grandDrawingFilter);
            let allDrawing = [];
            let firstGroup = getFirstGroup();
            let secondGroup = getSecondGroup();
            let thirdGroup = getThirdGroup();
            let drawingCardContainer = getDrawingCardContainer();
            let detailFilterCount = grandDrawingFilter.querySelector('.detail-filter-count');
            let detailFilters = {};


            $(document).ready( function () {
                getItemsFromAPI();
            });

            function getItemsFromAPI(){
                $.ajax({
                    type: 'GET',
                    url: '/drawingcatalog/getDrawingCatalog',
                    dataType: 'json',
                    cache: false,
                    beforeSend: function () {},
                    success: function(nodes) {
                        allDrawing = nodes;
                    },
                    error: function () {}
                });
            }

            $grandDrawingFilter.find('.detail-filters').on('change', function (e) {
                var $select = $(e.target);
                var detailFilterGroup = $select.attr('value-group');
                detailFilters[detailFilterGroup] = e.target.value;
                updateDetailFilterCount(detailFilterCount);
            });

            drawingCardContainer.classList.add("display-height-zero");
            drawingCardContainer.style.overflow = 'hidden';

            firstGroup.addEventListener('change', function() {

                let firstGroupVal = this.querySelector('select').value;
                let selectedIndex = this.querySelector('select').selectedIndex;

                if(selectedIndex !== 0 && secondGroup.classList.contains('disabled')) {
                    secondGroup.classList.replace("disabled", "enabled");
                }
                if(selectedIndex === 1 && secondGroup.classList.contains('enabled')) {
                    secondGroup.classList.replace("enabled", "disabled");
                }
                if( thirdGroup.classList.contains('enabled')) {
                    thirdGroup.classList.replace("enabled", "disabled");
                }
                if(selectedIndex === 0) {
                    drawingCardContainer.style.display = 'none';
                }
                else {
                    drawingCardContainer.style.display = 'block';
                }
                if(drawingCardContainer.classList.contains("display-height-full")) {
                    drawingCardContainer.classList.remove("display-height-full");
                }
                if(!drawingCardContainer.classList.contains("display-height-zero")) {
                    drawingCardContainer.classList.add("display-height-zero");
                }
                secondGroup.querySelector('select').selectedIndex = -1;
                thirdGroup.querySelector('select').selectedIndex = -1;
                $('option', secondGroup).filter(function(){
                    if (
                        $(this).attr('data-group') === firstGroupVal
                        || $(this).attr('data-group') === ''
                    ) {
                        if ($(this).parent('span').length) {
                            $(this).unwrap();
                        }
                    } else {
                        if (!$(this).parent('span').length) {
                            $(this).wrap( "<span>" ).parent().hide();
                        }
                    }
                });
                $(secondGroup).find('.selectpicker').selectpicker('refresh');
                $(thirdGroup).find('.selectpicker').selectpicker('refresh');
            });

            secondGroup.addEventListener('change', function() {
                let secondGroupVal = this.querySelector('select').value;
                var firstGroupVal = $(firstGroup).find(':selected')[0].value;
                var filteredItems1 = filterElements(allDrawing,firstGroupVal,1 );
                removeOldItems();
                var filteredItems2 = filterElements(filteredItems1, secondGroupVal, 2);
                var displayItem = filterElements(filteredItems2, '',4);

                $(drawingCardContainer).find('.neos-contentcollection').append(displayItem);
                updateDetailFilterCount(detailFilterCount);

                if(this.querySelector('select').selectedIndex !== 0 && thirdGroup.classList.contains('disabled')) {
                    thirdGroup.classList.replace("disabled", "enabled");
                }

                if(drawingCardContainer.classList.contains("display-height-full")) {
                    drawingCardContainer.classList.remove("display-height-full");
                }

                if(!drawingCardContainer.classList.contains("display-height-zero")) {
                    drawingCardContainer.classList.add("display-height-zero");
                }

                thirdGroup.querySelector('select').selectedIndex = -1;
                $('option', thirdGroup).filter(function(){
                    if (
                        $(this).attr('data-group') === secondGroupVal
                        || $(this).attr('data-group') === ''
                    ) {
                        if ($(this).parent('span').length) {
                            $(this).unwrap();
                        }
                    } else {
                        if (!$(this).parent('span').length) {
                            $(this).wrap( "<span>" ).parent().hide();

                        }
                    }
                });

                drawingCardContainer.classList.remove("display-height-zero");
                if(!drawingCardContainer.classList.contains("display-height-full")) {
                    setTimeout( function () {
                        drawingCardContainer.classList.add("display-height-full");
                    }, 375)
                }
                $(thirdGroup).find('.selectpicker').selectpicker('refresh');

            });

            thirdGroup.addEventListener('change', function() {
                let thirdGroupVal = this.querySelector('select').value;
                var firstGroupVal = $(firstGroup).find(':selected')[0].value;
                var secondGroupVal = $(secondGroup).find(':selected')[0].value;
                var filteredItems1 = filterElements(allDrawing, firstGroupVal, 1);
                var filteredItems2 = filterElements(filteredItems1, secondGroupVal, 2);
                removeOldItems();
                var filteredItems3 = filterElements(filteredItems2, thirdGroupVal, 3);
                $(drawingCardContainer).find('.neos-contentcollection').append(filteredItems3);
                drawingCardContainer.classList.remove("display-height-zero");
                if(!drawingCardContainer.classList.contains("display-height-full")) {
                    setTimeout( function () {
                        drawingCardContainer.classList.add("display-height-full");
                    }, 375)
                }
            });


            function updateDetailFilterCount(constElem) {
                var totalNr =  $(grandDrawingFilter).find('.detail-katalog-filter').children('.neos-contentcollection').children().length;
                $(constElem).text(totalNr);
            }

            function getFirstGroup(){
                return grandDrawingFilter.querySelector('[id*="firstGroup"]' );
            }
            function  getSecondGroup(){
                return grandDrawingFilter.querySelector('[id*="secondGroup"]' );
            }
            function getThirdGroup(){
                return grandDrawingFilter.querySelector('[id*="thirdGroup"]' );
            }
            function getDrawingCardContainer(){
                return grandDrawingFilter.querySelector('.detail-katalog-filter');
            }

            function filterElements(bigArray, groupVal, id ){
                var result = [];
                $.each( $(bigArray), function( i, data ){
                    var filterVal;
                    var selectedVal;
                    switch (id){
                        case 3:
                            selectedVal = groupVal.replace('.', '');
                            filterVal= $(data).find('.filter-item').attr('data-filterval').split(' ');
                            if(filterVal.includes(selectedVal)){
                                result.push(data);
                            }
                            break;
                        case 4:
                            filterVal= $(data).find('.filter-item').attr('data-filterval').split(' ').filter(e =>  e);
                            if(filterVal.length==2){
                                result.push(data);
                            }
                            break;
                        default:
                            selectedVal = groupVal.replace('.', '');
                            filterVal= $(data).find('.filter-item').attr('data-filterval').split(' ').filter(e =>  e);
                            if(filterVal.includes(selectedVal)){
                                result.push(data);
                            }
                    }
                });
                return result;
            }

            function removeOldItems() {
               $grandDrawingFilter.find('.detail-katalog-filter').children('.neos-contentcollection').empty();
            }

        });
    };
});