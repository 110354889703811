function clusterScroll() {
    if($('.scroll-trigger').length){
        var scrollTop = $(window).scrollTop(),
        elementOffset = $('.scroll-trigger').offset().top,
        distance = (elementOffset - scrollTop);

        const sections = document.querySelectorAll('.cluster-slide');

        gsap.registerPlugin(ScrollTrigger);

        var headlines = gsap.utils.toArray(".cluster-slide");


        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".scroll-trigger",
                pin: true,
                scrub: true,
                start: "top top",
                end: '+=' + (headlines.length * 100) + '%',
                toggleActions: 'play reverse none reverse',
                toggleClass: {targets: ".scroll-trigger", className: "is-active"},
                autoRefreshEvents: "visibilitychange,DOMContentLoaded,load,resize"
            }
        });


// Slides Scroll Animation
        tl.from(
            "[data-slide='data-dach'] ", {
                opacity: 1,
                duration: 1,
                stagger: 2
            },

            "+=1").to(
            "[data-slide='data-dach'] ", {
                opacity: 0,
                duration: 1,
                stagger: 2,
                ease: ""
            },

            "+=10").from(
            "[data-slide='data-fassade'] ", {
                opacity: 0,
                duration: 1,
                stagger: 2
            },

            "+=1").to(
            "[data-slide='data-fassade']", {
                opacity: 0,
                duration: 1,
                stagger: 2,
                ease: ""
            },

            "+=10").from(
            "[data-slide='data-ausbau']", {
                opacity: 0,
                duration: 1,
                stagger: 2
            },

            "+=1").to(
            "[data-slide='data-ausbau']", {
                opacity: 1,
                duration: 1,
                stagger: 2,
                ease: ""
            },

            "+=10");


        sections.forEach((section, index) => {

            ScrollTrigger.create({
                trigger: section,
                id: index + 1,
                start: 'top top-=' + (window.innerHeight * index),
                end: '+=100%',
                toggleActions: 'play reverse none reverse',
                toggleClass: {targets: section, className: "section-active"},

            })
        })

        function navClick() {
        gsap.utils.toArray(".slick-nav").forEach((a, i) => {
            a.clickElem = document.querySelector(a.hash);
            a.height = a.clickElem.offsetHeight;
            a.addEventListener("click", e => {
                e.preventDefault();
                gsap.to(window, {scrollTo: distance + a.height * (i + 1) - 76})

            });
        });
    }

    $(window).on('scroll', function () {
        if ($('.cluster-slide').hasClass('section-active')) {
            var att = $('.cluster-slide.section-active').attr('data-slide')
            $('.slick-slide').each(function () {
                if ($(this).attr('data-slide') === att) {
                    $(this).addClass('active-nav');
                } else {
                    $(this).removeClass('active-nav');
                }
            });
        }
    });

    $(window).on('resize', function () {
        var scrollTop = $(window).scrollTop(),
            elementOffset = $('.scroll-trigger').offset().top,
            distance = (elementOffset - scrollTop);
        navClick();
    });
    }

}

clusterScroll();

